export const COPYRIGHT = "https://fastcharging-admin.alessamotors.com/"
export const BASE_URL = window.location.port?'http://170.187.230.110:6555/api':'https://fastcharging-api.alessamotors.com/api'
export const DEBUG = true
if(!DEBUG){
    window.console.log = function() {
        return DEBUG;
    }
    window.console.debug = function() {
        return DEBUG;
    }
    window.console.info = function() {
        return DEBUG;
    }
    window.console.error = function() {
        return DEBUG;
    }
}
