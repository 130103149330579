import Page from 'components/Page';
import React from 'react';
import {
    Badge,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    CardImg,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import CardFooter from 'reactstrap/es/CardFooter';
import BasePage from './BasePage';
import Global, {
    BANNER_IMAGE_MAX_FILE_SIZE,
    DD_MM_YYYY_HH_MM_SS,
    PREVIEW_TYPES,
    previewTypeMap,
    WEBPAGE,
} from '../utils/Global';
import {allIsEmpty, cloneObject, imageSelector, parseDate} from '../utils/Utilities';
import {MdDelete, MdEdit} from 'react-icons/md';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import ActiveOption from '../components/Widget/ActiveOption';
import bannerPng from 'assets/img/bg/banner_placeholder.png';
import axios from 'axios';
import Img from 'react-image';
import 'react-datepicker/dist/react-datepicker.css';
import ActiveLabel from '../components/Widget/ActiveLabel';
import DateInput from '../components/Widget/DateInput';
import ImageCropper from '../components/modal/ImageCropper';
import {getData} from '../utils/StorageUtil';
import WarningLabel from '../components/Widget/WarningLabel';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import ItemOption from '../components/Widget/ItemOption';
import URLSelectionModal from '../components/modal/URLSelectionModal';
import label from '../message/Label'

const tableTypes = ['', 'bordered', 'striped', 'hover'];

class BannerPage extends BasePage{
  constructor(props) {
    super(props);
    this.state = {
      banners : [],
      banner:{
        previewType:WEBPAGE
      },
      previewType:PREVIEW_TYPES[0],
      modalAddConfirm:false,
      modalDeleteConfirm:false,
      modal: false,
      image:null,
      sortir:"created",
      ascending:true,
      editorState : EditorState.createEmpty(),
      urlSelectionModal:false
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchAll(this.state.ascending, this.state.sortir)
  }

  confirmSave = () =>{
    var banner = this.state.banner
    var image = this.state.image
    if(allIsEmpty(image, banner.startDate, banner.endDate, banner.pagelink, banner.sortir)
        || banner.sortir === 0){
      this.showDialog("Notice", "Please complete this form fields")
    }else{
      this.setState({
        modalAddConfirm:true
      })
    }
  }

  confirmDelete = () =>{
    this.setState({
      modalDeleteConfirm:true
    })
  }

  saveUpload = () => {
      var formData = new FormData();
      formData.append("bannerDtoGson", JSON.stringify(this.state.banner))
    if(this.state.imageBlob){
      formData.append("imageMultipartFile", this.state.imageBlob);
    }
      this.setState({
        modalAddConfirm:false
      }, () => {
        this.post(Global.API.BANNER_SAVE_UPLOAD, formData, null, res=>{
          if(res.code===200){
            this.setState({
              banner:res.data,
              image:res.data.imageLink,
            }, () => {
              this.showDialog("Success", res.message)
              this.fetchAll(this.state.ascending, this.state.sortir)
            })
          }
        },  true, true)
      })
    // })
  }


  delete = () => {
    this.setState({
      modalDeleteConfirm:false
    }, () => {
      this.get(Global.API.BANNER_DELETE+"/"+this.state.banner.id, null, null, res => {
        if(res.code===200){
          this.setState({
            banner:null
          }, () => {
            this.fetchAll(this.state.ascending, this.state.sortir)
          })
        }
      }, true, true);
    })
  }

  closeDialog = () =>{
    this.setState({
      modalAddConfirm:false,
      modalDeleteConfirm:false
    })
  }

  fetchAll = (ascending, sortir) => {
      let editorState = this.state.editorState
      this.get(Global.API.BANNERS,{
      params:{
        ascending:ascending,
        sortir:sortir,
      }
    }, null, res =>{
      if(res.code === 200){
          let banners = res.data
          this.setState({
                banners : banners
            })
      }
    }, true, true);
  }

  onEditorStateChange= (editorState) => {
    let banner = this.state.banner
    banner.description = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    this.setState({
      banner:banner,
      editorState:editorState,
    });
  }


  render() {
    let configuration = getData(Global.CONFIGURATION)
    let imageAspect = 4/4
    if(configuration.bannerWidth && configuration.bannerHeight){
      imageAspect = configuration.bannerWidth/configuration.bannerHeight
    }
    let previewType = this.state.previewType
    let banner = this.state.banner?this.state.banner:{}
    let banners = this.state.banners?this.state.banners:[]
    let editorState = this.state.editorState
    let urlSelectionModal = this.state.urlSelectionModal

    return (
        <Page
            title="Banner"
            breadcrumbs={[{ name: 'banner', active: true }]}
            className="TablePage">
          <ConfirmDialog
              showing={this.state.modalAddConfirm}
              title={label.UpdateConfirmation}
              message="Do you want to update this banner item to your list ?"
              okCallback={this.saveUpload}
              cancelCallback={this.closeDialog}/>
          <ConfirmDialog
              showing={this.state.modalDeleteConfirm}
              title={label.DeleteConfirmationTitle}
              message="Do you want to delete this banner item from your list ?"
              okCallback={this.delete}
              cancelCallback={this.closeDialog}/>
          {super.render()}
          <URLSelectionModal
              showing={urlSelectionModal}
              okCallback={(selectedUrl)=>{
                banner.pagelink = selectedUrl
                this.setState({
                  urlSelectionModal:false,
                  banner:banner
                })
              }}
              cancelCallback={()=>{
                if(!banner.pagelink){
                  banner.previewType = WEBPAGE
                  banner.pagelink = ""
                }
                this.setState({
                  urlSelectionModal:false,
                  banner:banner
                })
              }}
              selectedUrl={banner.pagelink}/>
          <Row>
            <Col md={6}>
              <Card>
                <CardHeader>Desktop</CardHeader>
                <CardBody>
                  <Row>
                    <CardImg top style={{width:'100%', height:'100%'}} src={
                      this.state.image!=null?this.state.image:bannerPng}
                        onError={(e)=>
                           this.defaultImage(e, bannerPng)
                        }/>
                  </Row>
                  <Row>
                    <Col>
                      <WarningLabel message={"*Use Jpeg Format / Max "+BANNER_IMAGE_MAX_FILE_SIZE+" Kb"}/>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Row>
                    <Col>
                      <ButtonGroup className="float-left">
                        <Button color="primary" onClick={e=>(
                            imageSelector(file =>{
                              this.setState({
                                image:file,
                                imageCropperModal:true
                              })
                            }, null, BANNER_IMAGE_MAX_FILE_SIZE).click()
                        )}>Upload</Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardHeader>Title & Description</CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="title">{label.Title}</Label>
                        <Input
                            name="title"
                            value={banner.title?banner.title:""}
                            onChange={(e) =>{
                              banner.title = e.target.value
                              this.setState({
                                banner:banner
                              })
                            }}
                            placeholder={label.EnterTitle}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="description">{label.Description}</Label>
                        <Editor
                            toolbar={
                              {
                                image: {
                                  className: undefined,
                                  component: undefined,
                                  popupClassName: undefined,
                                  urlEnabled: true,
                                  uploadEnabled: false,
                                  alignmentEnabled: true,
                                  previewImage: true,
                                  inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                  alt: {present: false, mandatory: false},
                                  defaultSize: {
                                    height: 'auto',
                                    width: 'auto',
                                  }
                                }
                              }
                            }
                            editorState={editorState}
                            onEditorStateChange={this.onEditorStateChange}
                            style={{height:'500px'}}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="startDate">{label.StartDate}</Label>
                        <DateInput
                            id="startDate"
                            maxdate={banner.endDate}
                            value={banner.startDate}
                            onChange={(e) =>{
                              let value = e.target.value
                              banner.startDate = value
                              this.setState({
                                banner:banner
                              })
                            }}
                            placeholder={label.EnterStartDate}
                        />

                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="endDate">{label.EndDate}</Label>
                        <DateInput
                            id="endDate"
                            mindate={banner.startDate}
                            value={banner.endDate}
                            onChange={(e) =>{
                              let value = e.target.value
                              banner.endDate = value
                              this.setState({
                                banner:banner
                              })
                            }}
                            placeholder={label.EnterEndDate}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="sortir">{label.Sortir}</Label>
                        <Input
                            type="text"
                            name="sortir"
                            value={(banner.sortir!=undefined)?banner.sortir:""}
                            onChange={(e) =>{
                              banner.sortir = e.target.value.replace(/\D/, '')
                              this.setState({
                                banner:banner
                              })
                            }}
                            placeholder={label.EnterSortir}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <ActiveOption
                          callback={(active)=>{
                            banner.active = active
                            this.setState({
                              banner:banner
                            })
                          }}
                          default={banner.active}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <ItemOption
                            title={"Preview Type"}
                            objects={PREVIEW_TYPES}
                            default={previewType?previewType.id:null}
                            hideOptionLabel={true}
                            callback={(previewType)=>{
                              banner.previewType = previewType.name
                              banner.pagelink = ""
                              if(previewType.id===2){
                                urlSelectionModal = true
                              }else{
                                urlSelectionModal = false
                              }
                              this.setState({
                                previewType:previewType,
                                banner:banner,
                                urlSelectionModal:urlSelectionModal
                              })
                            }}
                        />
                      </FormGroup>
                    </Col>
                    {
                      (previewType.id===2)&&(
                          <Col md={1}>
                            <FormGroup>
                              <Label style={{display:'block'}}>&nbsp;</Label>
                              <Button onClick={event => {
                                this.setState({
                                  urlSelectionModal:true
                                })
                              }}><MdEdit/></Button>
                            </FormGroup>
                          </Col>
                      )
                    }
                    <Col md={previewType.id===2?6:7}>
                      <FormGroup>
                        <Label for="pagelink">{label.PageLink}</Label>
                        <Input
                            type="link"
                            name="pagelink"
                            value={banner.pagelink?banner.pagelink:""}
                            onChange={(e) =>{
                              banner.pagelink = e.target.value
                              this.setState({
                                banner:banner
                              })
                            }}
                            readOnly={previewType.id===2}
                            placeholder={previewType.id===2?"Auto generate...":"Enter pagelink..."}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <Label for="pagelink">Go Pagelink</Label>
                        <br/>
                        <Badge href={banner.pagelink} color="primary" className="form-text mr-1" target="_blank">
                          Go site
                        </Badge>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Row>
                    <Col>
                      <ButtonGroup className="float-left">
                        <Button color="danger" onClick={e => (
                            this.setState({
                              banner:{previewType:WEBPAGE},
                              image:null
                            })
                        )}>{label.Cancel}</Button>
                      </ButtonGroup>
                    </Col>
                    <Col>
                      <ButtonGroup className="float-right">
                        <Button color="primary" onClick={e=>(
                            this.confirmSave()
                        )}> {
                          (banner && banner.id)?"Update":"Save"
                        } </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <Row>
            <Card body>
              <Col>
                <Row>
                  <UncontrolledButtonDropdown key={1}>
                    <DropdownToggle
                        caret
                        color="white"
                        className="text-capitalize m-1">
                      {label.SortBy}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>{label.SortBy}</DropdownItem>
                      <DropdownItem>{label.Name}</DropdownItem>
                      <DropdownItem>Product/Qty</DropdownItem>
                      <DropdownItem>{label.Updated}</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                  <UncontrolledButtonDropdown key={2}>
                    <DropdownToggle
                        caret
                        color="white"
                        className="text-capitalize m-1">
                      {label.Sortir}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>{label.Sortir}</DropdownItem>
                      <DropdownItem>{label.Ascending}</DropdownItem>
                      <DropdownItem>{label.Descending}</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </Row>
                <Row>
                  <Col>
                    <Table responsive hover>
                      <thead>
                      <tr>
                        <th>#</th>
                        <th>{label.Title}</th>
                        <th>Start date - End date</th>
                        <th>{label.Sortir}</th>
                        <th>Pagelink</th>
                        <th>{label.Status}</th>
                        <th width="20%">Desktop</th>
                        <th>{label.Updated}</th>
                        <th>{label.Edit}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        banners.map((item, index) =>(
                            <tr key={index}>
                              <th scope="row">{++index}</th>
                              <td>{item.title}</td>
                              <td>{parseDate(item.startDate, 'dddd dd-mmm-yyyy')} - {parseDate(item.endDate, 'dddd dd-mmm-yyyy')}</td>
                              <td>{item.sortir}</td>
                              <td><a href={item.pagelink} target="_blank">Go site</a></td>
                              <td><ActiveLabel active={item.active}/></td>
                              <td width="20%">
                                <Img
                                    className="img-thumbnail"
                                    src={item.imageLink}
                                    onClick={e=>{
                                      this.setState({
                                        imageLink:item.imageLink,
                                        openPreview:true
                                      })
                                    }}
                                />
                              </td>
                              <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                              <td>
                                <Button size="sm" color="danger" onClick={e => {
                                  this.setState({
                                    banner:cloneObject(item),
                                    previewType:previewTypeMap(item.previewType),
                                    image:cloneObject(item.imageLink),
                                  }, () => {
                                    this.confirmDelete()
                                  })
                                }}>
                                  <MdDelete/>
                                </Button>
                                &nbsp;
                                <Button size="sm" color="primary" onClick={ e => {
                                    if(item.description){
                                        const descriptionContentBlock = htmlToDraft(item.description);
                                        if (descriptionContentBlock) {
                                            const contentState = ContentState.createFromBlockArray(descriptionContentBlock.contentBlocks);
                                            editorState = EditorState.createWithContent(contentState);
                                        }else{
                                            editorState = EditorState.createEmpty()
                                        }
                                    }else{
                                        editorState = EditorState.createEmpty()
                                    }

                                    this.setState({
                                        banner:cloneObject(item),
                                        previewType:previewTypeMap(item.previewType),
                                        image:cloneObject(item.imageLink),
                                        editorState:editorState
                                    })
                                }}>
                                  <MdEdit/>
                                </Button>
                              </td>
                            </tr>
                        ))
                      }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Col>
            </Card>
          </Row>
          <ImageCropper src={this.state.image} aspect={imageAspect} show={this.state.imageCropperModal} callback={file=>{
            axios({
              url: file,
              method: 'GET',
              responseType: 'blob', // important
            }).then((response) => {
              var fileBlob = response.data ;
              this.setState({
                image:file,
                imageCropperModal:false,
                imageBlob:fileBlob,
              })
            })
          }} cancelCallback={()=>{
            this.setState({
              image:null,
              imageCropperModal:false,
              imageBlob:null,
            })
          }}/>
        </Page>
    );
  }
}
export default Object.assign(BannerPage, {ENDPOINT : "/banners"})