import React from 'react';
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ValidatorLabel from '../Widget/ValidatorLabel';
import PropTypes from 'prop-types';
import Global from '../../utils/Global';
import BaseComponent, {toast} from '../BaseComponent';
import {saveAdminSession} from '../../utils/StorageUtil';
import label from '../../message/Label'

/*= (message, okCallback, cancelCallback) =>*/

export default class ChangePasswordDialog extends BaseComponent {

    constructor(props){
        super(props);
        let state = this.state
        state.modal = this.props.modal
        state.oldPassword = null
        state. newPassword = null
        state.confirmPassword = null
        state.oldPasswordError = null
        state.newPasswordError = null
        state.confirmPasswordError = null
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        if(this.props!=props){
            this.setState({
                modal:props.modal,
                oldPassword:null,
                newPassword:null,
                confirmPassword:null,
                oldPasswordError:null,
                newPasswordError:null,
                confirmPasswordError:null,
            })
        }
    }

    changePassword=()=>{
        let state = this.state
        state.oldPasswordError=null
        state.newPasswordError=null
        state.confirmPasswordError=null

        let oldPassword = state.oldPassword
        let newPassword = state.newPassword
        let confirmPassword = state.confirmPassword
        if(!oldPassword || oldPassword===''){
            state.oldPasswordError = label.OldPasswordMustBeEntered
            this.setState(state)
            return
        }
        if(!newPassword || newPassword===''){
            state.newPasswordError = label.NewPasswordMustBeEntered
            this.setState(state)
            return
        }
        if(!confirmPassword || confirmPassword===''){
            state.confirmPasswordError = label.ConfirmPasswordMustBeEntered
            this.setState(state)
            return
        }
        if(newPassword.length<6){
            state.newPasswordError = label.PasswordMustAtLeastContain6Characters
            this.setState(state)
            return
        }
        if(confirmPassword.length<6){
            state.confirmPasswordError = label.PasswordMustAtLeastContain6Characters
            this.setState(state)
            return
        }

        if(oldPassword===newPassword){
            state.newPasswordError = label.OldPasswordAndNewPasswordCannotBeSame
            this.setState(state)
            return
        }
        if(confirmPassword!=newPassword){
            state.confirmPasswordError = label.ConfirmPasswordAndNewPasswordIsNotSame
            this.setState(state)
            return
        }

        let formData = new FormData()
        this.openConfirmDialog(label.Confirmation, label.DoYouWantToChangeYourPassword, ()=>{
            formData.append("oldPassword", oldPassword);
            formData.append("newPassword", newPassword);
            this.post(Global.API.ADMIN_CHANGEWAITING_FOR_PAYMENT_IDWORD, formData, null, response=>{
                if(response.code===200){
                    saveAdminSession(response.data, true)
                    toast.success(response.message)
                    this.setState({
                        modal:false
                    }, () => {
                        if(this.props.okCallback!=null){
                            this.props.okCallback()
                        }
                    })
                }
            }, true, true)
        }, ()=>{

        })
    }


    render(){
        return (
            <Modal
                isOpen={this.state.modal}
                centered={true}
                backdrop={true}>
                {super.render()}
                <ModalHeader>
                    {label.ChangePassword}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="oldPassword">{label.OldPassword}</Label>
                                <Input
                                    type="password"
                                    name="oldPassword"
                                    value={(this.state.oldPassword!=null && this.state.oldPassword)?this.state.oldPassword:""}
                                    onChange={(e) =>{
                                        this.setState({
                                            oldPassword:e.target.value
                                        })
                                    }}
                                    placeholder={label.EnterOldPassword}
                                />
                                <ValidatorLabel message={this.state.oldPasswordError}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="newPassword">{label.NewPassword}</Label>
                                <Input
                                    type="password"
                                    name="newPassword"
                                    value={(this.state.newPassword!=null && this.state.newPassword)?this.state.newPassword:""}
                                    onChange={(e) =>{
                                        this.setState({
                                            newPassword:e.target.value
                                        })
                                    }}
                                    minLength={6}
                                    placeholder={label.EnterNewPassword}
                                />
                                <ValidatorLabel message={this.state.newPasswordError}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="confirmPassword">{label.ConfirmPassword}</Label>
                                <Input
                                    type="password"
                                    name="confirmPassword"
                                    value={(this.state.confirmPassword!=null && this.state.confirmPassword)?this.state.confirmPassword:""}
                                    onChange={(e) =>{
                                        this.setState({
                                            confirmPassword:e.target.value
                                        })
                                    }}
                                    minLength={6}
                                    placeholder={label.EnterConfirmPassword}
                                />
                                <ValidatorLabel message={this.state.confirmPasswordError}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="secondary" onClick={e=>{
                        e.preventDefault()
                        this.setState({
                            modal:false
                        }, () => {
                            if(this.props.cancelCallback!=null){
                                this.props.cancelCallback()
                            }
                        })
                    }}>
                        {label.Cancel}
                    </Button>
                    &nbsp;
                    <Button
                        color="warning" onClick={e=>{
                        e.preventDefault()
                        this.changePassword()
                    }}>
                        {label.Save}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

ChangePasswordDialog.propTypes = {
    modal: PropTypes.bool.isRequired,
    okCallback: PropTypes.func,
    cancelCallback:PropTypes.func,
}
