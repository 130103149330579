import React from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col, Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
    ButtonGroup
} from 'reactstrap';
import {isResponseOk, parseDate} from '../../utils/Utilities';
import Global, {DD_MM_YYYY_HH_MM_SS} from '../../utils/Global';
import label from '../../message/Label'
import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";
import Checkbox from "../Checkbox";
import {MdRefresh} from "react-icons/all";


export default class ChargerConfigurationModal extends BaseComponent{

    constructor(props) {
        super(props);
        let charger = this.props.charger?this.props.charger:{}
        let chargerConfigurations = charger.chargerConfigurations?charger.chargerConfigurations:[]
        this.state ={
            charger:charger,
            chargerConfigurations:chargerConfigurations
        }
    }

    componentWillReceiveProps(props, nextContext) {
        if(props.showing){
            let charger = props.charger?props.charger:{}
            let chargerConfigurations = charger.chargerConfigurations?charger.chargerConfigurations:[]

            this.setState({
                charger:charger, chargerConfigurations:chargerConfigurations
            }, async () => {
                await this.fetchChargerConfiguration()
            })
        }
    }

    async fetchChargerConfiguration(){
        let {charger} = this.state
        let params = {chargerId:charger.id}
        let response = await this.asyncGet(Global.API.CHARGER_CONFIGURATION, {params},null, true, true);
        if(isResponseOk(response)){
            let chargerConfigurations = response.data
            this.setState({chargerConfigurations})
        }

    }

    async save(){
        let {charger, chargerConfigurations} = this.state
        // let params = {chargerId:charger.id, chargerConfigurationsGson: JSON.stringify(chargerConfigurations)}
        let form = new FormData()
        form.append("chargerId", charger.id)
        chargerConfigurations.map((item, index)=>{
            item.charger = undefined;
            return item;
        })
        form.append("chargerConfigurationsGson", JSON.stringify(chargerConfigurations))

        let response = await this.asyncPost(Global.API.CHARGER_CONFIGURATION_SAVE, form,null, true, true);
        if(isResponseOk(response)){
            let charger = response.data
            this.successToast(response.message)
            this.setState({charger}, () => {
                this.fetchChargerConfiguration()
            })
        }
    }
    async resetAll(){
        let {charger} = this.state
        // let params = {chargerId:charger.id}
        let form = new FormData()
        form.append("chargerId", charger.id)
        let response = await this.asyncPost(Global.API.CHARGER_CONFIGURATION_RESET_ALL, form,null, true, true);
        if(isResponseOk(response)){
            let charger = response.data
            this.successToast(response.message)
            this.setState({charger}, () => {
                this.fetchChargerConfiguration()
            })
        }
    }
     fetchCurrent(){
        let {charger, chargerConfigurations} = this.state
        let params = {chargerId:charger.id}
        this.get(Global.API.DEVICE_CHARGER_CONFIGURATION, {params},null, (response)=>{
            if(isResponseOk(response)){
                let deviceChargerConfigurations = response.data
                // console.log(JSON.stringify(deviceChargerConfigurations))
                chargerConfigurations.forEach((item, index)=>{
                    deviceChargerConfigurations.forEach((deviceItem, deviceIndex)=>{
                        if(item.key===deviceItem.key){
                            chargerConfigurations[index].current = deviceItem.value
                        }
                    })
                })
                this.setState({chargerConfigurations})
            }
        },true, true);


    }




    render() {
        let {charger, chargerConfigurations} = this.state

        return (
            <>
                {super.render()}
                <Modal
                    autoFocus={false}
                    style={{minWidth:'90%'}}
                    size="xl"
                    centered={true}
                    isOpen={this.props.showing}
                    backdrop={true}>
                    {super.render()}
                    <ModalHeader>
                        {label.ChargerConfiguration}
                    </ModalHeader>
                    <ModalBody>
                        {this.renderChargerConfigurationesTable()}
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup>
                            <Button
                                color="secondary"
                                onClick={e=>{
                                    e.preventDefault()
                                    this.props.onClose()
                                }}>
                                {label.Close}
                            </Button>
                            &nbsp;
                            <Button
                                color="danger"
                                onClick={e=>{
                                    e.preventDefault()
                                    this.resetAll()
                                }}>
                                {label.ResetAll}
                            </Button>
                            &nbsp;
                            <Button
                                color="warning"
                                onClick={e=>{
                                    e.preventDefault()
                                    this.save()
                                }}>
                                {label.Save}
                            </Button>
                        </ButtonGroup>
                    </ModalFooter>
                </Modal>

            </>
        )
    }



    renderChargerConfigurationesTable = () =>{
        let {chargerConfigurations} = this.state
        return (
            <Table responsive hover>
                <thead>
                <tr>
                    <th>#</th>
                    <th>{label.Key}</th>
                    <th>{label.ReadOnly}</th>
                    <th style={{width:'25%'}}>{label.Value}</th>
                    <th style={{width:'25%'}}>{label.Original}</th>
                    <th style={{width:'25%'}}><Button onClick={()=>{
                        this.fetchCurrent()
                    }}>{label.Charger}&nbsp;<MdRefresh/></Button></th>
                    {/*<th>{label.Updated}</th>*/}
                </tr>
                </thead>
                <tbody>
                {
                    chargerConfigurations.map((item, index)=>(
                        <tr key={item.id}>
                            <th scope="row">{(++index)}</th>
                            <td style={{overflowWrap:'anywhere'}}>{item.key}</td>
                            <td><Checkbox checked={item.readonly} disabled/></td>
                            <td style={{overflowWrap:'break-word'}}>
                                <Input
                                    type="textarea"
                                    name="code"
                                    lines={3}
                                    value={item.value}
                                    onChange={(e) =>{
                                        e.preventDefault()
                                        chargerConfigurations[index-1].value = e.target.value
                                        this.setState({
                                            chargerConfigurations:chargerConfigurations
                                        })
                                    }}
                                    readOnly={item.readonly}
                                />
                            </td>
                            <td style={{overflowWrap:'anywhere'}}>{item.original}</td>
                            <td style={{overflowWrap:'anywhere'}}>{item.current}</td>
                            {/*<td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>*/}
                        </tr>
                    ))
                }
                </tbody>
            </Table>
        )
    }


}

ChargerConfigurationModal.propTypes = {
    showing:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    charger:PropTypes.object.isRequired
}
