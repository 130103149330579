import React from 'react';
import {Button, Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table,} from 'reactstrap';
import {parseDate} from '../../utils/Utilities';
import Global, {DD_MM_YYYY_HH_MM_SS} from '../../utils/Global';
import label from '../../message/Label'
import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";
import SearchInput from "../SearchInput";
import Checkbox from "../Checkbox";
import ModalPagination from "../ModalPagination";
import queryString from "query-string";


export default class CompanySelectionModal extends BaseComponent{

    constructor(props) {
        super(props);
        let company = this.props.company?this.props.company:{}
        let companies = []
        this.state ={
            company:company,
            companies:companies,
            sortir:'jie.updated',
            page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
            search:queryString.parse(this.props.query).search?queryString.parse(this.props.query).search:"",
            inputSearch : "",
            totalPage:0,
            totalElement:0,
            pageElement:0,
            showing:this.props.showing
        }
    }

    componentWillReceiveProps(props, nextContext) {
        if(props.showing){
            let company = props.company?props.company:{}
            let companies = []
            let page = queryString.parse(props.query).page?queryString.parse(props.query).page:1
            let search = queryString.parse(props.query).search?queryString.parse(props.query).search:""
            this.setState({
                company:company,
                companies:companies,
                page:page,
                search:search,
                showing:props.showing
            },() => {
                if(props.showing){
                    this.fetchCompanies()
                }
            })
        }
    }
    componentDidMount() {
        super.componentDidMount();
        let {showing} = this.state
        if(showing){
            this.fetchCompanies()

        }
    }

    fetchCompanies(){
        let {ascending, sortir, page} = this.state
        this.get(Global.API.COMPANIES, {
            params:{
                active:true,
                page:page-1,
                ascending:ascending,
                sortir:sortir
            }
        }, null, response=>{
            if(response.code===200){
                this.setState({
                    companies:response.data,
                    totalPage: response.totalPage,
                    totalElement: response.totalElement,
                    pageElement: response.pageElement,
                })
            }
        }, false, false)
    }

    render() {
        let {company, companies} = this.state

        return (
            <>
                {super.render()}
                <Modal
                    autoFocus={false}
                    style={{minWidth:'90%'}}
                    size="xl"
                    centered={true}
                    isOpen={this.props.showing}
                    backdrop={true}>
                    {super.render()}
                    <ModalHeader>
                        {label.Companies}
                    </ModalHeader>
                    <ModalBody>
                        {this.renderCompanies()}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="warning"
                            onClick={e=>{
                                e.preventDefault()
                                this.props.onClose()
                            }}>
                            {label.Cancel}
                        </Button>
                        <Button
                            color="secondary"
                            onClick={e=>{
                                e.preventDefault()
                                this.props.onSelect(company)
                            }}>
                            {label.Select}
                        </Button>
                    </ModalFooter>
                </Modal>

            </>
        )
    }

    renderCompanies = () =>{
        return (
            <Row key={2}>
                <Col>
                    <Row>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col>
                            <SearchInput placeholder={"Search Company"}/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            {this.renderCompaniesTable()}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }


    renderCompaniesTable = () =>{
        let {companies, company} = this.state
        return (
            <Card>
                <CardBody>
                    <Table responsive hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{label.Name}</th>
                            <th>{label.Description}</th>
                            <th>{label.Created}</th>
                            <th>{label.Updated}</th>
                            <th>{label.Select}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                           companies.map((item, index)=>(
                                <tr key={item.id}>
                                    <th scope="row">{(++index)}</th>
                                    <td>{item.fullName}</td>
                                    <td>{item.companyDescription}</td>
                                    <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                    <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                    <td>
                                        <Checkbox
                                            checked={company&&(company.id===item.id)}
                                            onChange={
                                                checked => {
                                                    if(checked && checked===true){
                                                        company = item
                                                    }else{
                                                        company = {}
                                                    }
                                                    this.setState({
                                                        company:company
                                                    })
                                                }
                                            }/>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </CardBody>
                <ModalPagination {...this.props} currentPage={this.state.page} pageCount={this.state.totalPage} onSelect={(page)=>{
                    this.setState({
                        page:page
                    }, () => {
                        this.fetchCompanies()
                    })
                }}/>
            </Card>
        )
    }


}

CompanySelectionModal.propTypes = {
    showing:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    onSelect:PropTypes.func.isRequired,
    company:PropTypes.object
}
