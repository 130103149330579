export function transactionEventsFromNative(datas){
    let transactionEvents = [];

    if(datas.length>0) datas.map((data)=>{
        let transactionEvent = {}
        let transaction = {};
        let product = {};
        let productCategory = {};
        let transactionStatus = {};
        let user = {}
        transactionEvent.id = data[0]
        transactionEvent.serialCode = data[2]
        transactionEvent.customer_order_no = data[3]
        transactionEvent.productSKU=data[4]
        transactionEvent.productName=data[5]
        transactionEvent.performerName=data[8]
        transactionEvent.performerRole=data[9]
        transactionEvent.created = data[12]

        productCategory.name = data[7]

        product.systemType = data[6]
        product.productCategory = productCategory

        transaction.reference = data[1]
        transaction.product = product

        user.id = data[13]
        user.fullname = data[14]+" "+data[15]

        transactionStatus.name = data[10]
        transactionStatus.label = data[11]

        transactionEvent.transaction = transaction
        transactionEvent.transactionStatus = transactionStatus
        if (data[13]){
            transactionEvent.user = user
        }
        transactionEvents.push(transactionEvent)
    })

    console.log("generatedEvents",transactionEvents);
    return transactionEvents;

}