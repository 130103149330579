import React from 'react';
import BasePage from './BasePage';
import Page from '../components/Page';
import Global, {DD_MM_YYYY_HH_MM_SS} from '../utils/Global';
import queryString from 'query-string';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import label from '../message/Label';
import {Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row, Table,} from 'reactstrap';
import {allIsEmpty, currencyFormat, parseDate} from "../utils/Utilities";
import MyCardImage from "../components/Card/MyCardImage";
import userProfilePicture from "../assets/img/users/user_pp.png";
import {MdPageview} from "react-icons/all";
import SockJsClient from "react-stomp";


class TransactionDetailPage extends BasePage{
    successToast(message) {
        super.successToast(message);
    }

    constructor(props) {
        super(props);
        let state = this.state
        state.transaction = {}
        state.transactionState = {}
        state.transactionStatusHistories = []
        state.transactionIdQuery = queryString.parse(this.props.query).id
        state.transactionCategory = {}
        state.ascending = true
        state.sortir = 'created'
        state.chargingTask = {}
        this.setState(state)
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchTransactionDetail(this.state.transactionIdQuery)
    }

    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            this.setState({
                page:queryString.parse(props.query).page?queryString.parse(props.query).page:1
            }, () => {
                // this.fetchAllInventories(this.state.transaction.id, this.state.ascending, this.state.sortir, true)
            })
        }
    }

    fetchTransactionDetail = (id) => {
        if(id!=null){
            this.get(Global.API.TRANSACTION, {
                params :{
                    id : id
                }
            }, null, response=>{
                if(response.code===200){
                    let transaction = response.data
                    let transactionState = transaction.transactionState
                    this.setState({
                        transaction,transactionState
                    }, () => {
                        this.fetchTransactionStatusHistories();
                        this.fetchChargingTask(id);
                    })
                }else{
                    this.props.history.goBack();
                }
            }, true, true)
        }
    }

    fetchChargingTask = (id) => {
        if(id!=null){
            this.get(Global.API.TRANSACTION_CHARGING_TASK, {
                params :{
                    id : id
                }
            }, null, response=>{
                if(response.code===200){
                    let chargingTask = response.data
                    this.setState({
                        chargingTask
                    })
                }
            }, true, true)
        }
    }


    fetchTransactionStatusHistories = () =>{
        let transaction = this.state.transaction?this.state.transaction:{}
        this.get(Global.API.TRANSACTION_STATUS_HISTORIES, {
            params:{
                transactionId:transaction.id,
                sortir:"created",
                ascending:false,
            }
        }, null, res =>{
            if(res.code === 200){
                this.setState({
                    transactionStatusHistories : res.data
                })
            }
        }, true, true);
    }


    closeDialog = () =>{
        this.setState({
            modalAddConfirm:false,
            modalDeleteConfirm:false,
        })
    }



    render() {
        let {transaction, transactionStatusHistories, transactionState, chargingTask} = this.state
        let chargerGun = transaction.chargerGun?transaction.chargerGun:{}
        let charger = chargerGun.charger?chargerGun.charger:{}
        let station = charger.station?charger.station:{}
        let user = transaction.user?transaction.user:{}
        let subPaymentMedia = transaction.subPaymentMedia?transaction.subPaymentMedia:{}
        let paymentMedia = subPaymentMedia.paymentMedia?subPaymentMedia.paymentMedia:{}
        let transactionStatus = transaction.transactionStatus?transaction.transactionStatus:{}
        return (
            <Page
                title={label.TransactionDetail}
                breadcrumbs={[{ name: label.TransactionDetail, active: true }]}>
                {super.render()}

                <Card>
                    <CardHeader>{label.TransactionDetail}</CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={3}>
                                <Card>
                                    <CardHeader>{label.Purchase}</CardHeader>
                                    <CardBody style={{textAlign:'center'}}>
                                        <span style={{fontSize:'54px', fontWeight:'bold'}}>{currencyFormat(transaction.amountPrice, 'Rp')}</span>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={6}></Col>
                            {/*<Col md={3}>*/}
                            {/*    <Card>*/}
                            {/*        <CardHeader>{label.Refund}</CardHeader>*/}
                            {/*        <CardBody style={{textAlign:'center'}}>*/}
                            {/*            <span style={{fontSize:'54px', fontWeight:'bold'}}>{currencyFormat(transaction.refundPriceAmount, 'Rp')}</span>*/}
                            {/*        </CardBody>*/}
                            {/*    </Card>*/}
                            {/*</Col>*/}
                            <Col md={3}>
                                <Card>
                                    <CardHeader>{label.Status}</CardHeader>
                                    <CardBody style={{textAlign:'center'}}>
                                        <span style={{fontSize:'44px', fontWeight:'bold', color:transactionStatus.color}}>{transactionStatus.label}</span>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <Card>
                                    <CardHeader>{label.Customer}</CardHeader>
                                    <CardBody>
                                        <MyCardImage top src={
                                            user.photoUrl!=null?user.photoUrl:userProfilePicture}
                                                     onClick={e=>{
                                                         if(!allIsEmpty(user, user.id)) {
                                                             this.openLightBoxSingleImage(user.photoUrl)
                                                         }
                                                     }}
                                                     onError={(elm)=>this.defaultImage(elm, userProfilePicture)}/>
                                    </CardBody>
                                    <CardBody>
                                        <Row>
                                            <Col md={10}>
                                                <FormGroup>
                                                    <Label for="fullname">{label.Fullname}</Label>
                                                    <Input name={"fullname"} value={user.fullname} disabled/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="fullname">&nbsp;&nbsp;</Label>
                                                    <Button><MdPageview/></Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="email">{label.Email}</Label>
                                                    <Input name={"email"} value={user.email} disabled/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="mobilePhone">{label.MobilePhone}</Label>
                                                    <Input name={"mobilePhone"} value={user.mobilePhone} disabled/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader>{label.TransactionInfo}</CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="reference">{label.Reference}</Label>
                                                            <Input name={"reference"} value={transaction.reference} disabled/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="paymentReference">{label.PaymentReference}</Label>
                                                            <Input name={"paymentReference"} value={transaction.paymentReference} disabled/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="paymentMethod">{label.PaymentMethod}</Label>
                                                            <Input name={"paymentMethod"} value={subPaymentMedia.label} disabled/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="paymentMedia">{label.PaymentMedia}</Label>
                                                            <Input name={"paymentMedia"} value={paymentMedia.label} disabled/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader>{label.OtherInformation}</CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="reference">{label.Station}</Label>
                                                            <Input name={"reference"} value={station.name} disabled/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="paymentReference">{label.Charger}</Label>
                                                            <Input name={"paymentReference"} value={charger.name} disabled/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                {chargingTask && (<Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="chargingDuration">{label.ChargingDuration}</Label>
                                                            <Input name={"chargingDuration"} value={chargingTask.chargingDuration} disabled/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>)}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={3}>
                                <Card>
                                    <CardHeader>
                                        {label.AmountInformation}
                                    </CardHeader>
                                    <CardBody>
                                        {/*<Row>*/}
                                        {/*    <Col>*/}
                                        {/*        <FormGroup>*/}
                                        {/*            <Label>{label.PricePerKW}</Label>*/}
                                        {/*            <Input disabled value={currencyFormat(transaction.pricePerKW, 'Rp')}/>*/}
                                        {/*        </FormGroup>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>{label.BuyAmount}</Label>
                                                    <Input disabled value={currencyFormat(transaction.amountPrice, 'Rp')}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>{label.AdminFee}</Label>
                                                    <Input disabled value={currencyFormat(transaction.totalAdminFee, 'Rp')}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {/*<Row>*/}
                                        {/*    <Col>*/}
                                        {/*        <FormGroup>*/}
                                        {/*            <Label>{label.ParkingHour}</Label>*/}
                                        {/*            <Input disabled value={transaction.parkingHour}/>*/}
                                        {/*        </FormGroup>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                        {/*<Row>*/}
                                        {/*    <Col>*/}
                                        {/*        <FormGroup>*/}
                                        {/*            <Label>{label.ParkingFeeHour}</Label>*/}
                                        {/*            <Input disabled value={currencyFormat(transaction.parkingFeePerHour, 'Rp')}/>*/}
                                        {/*        </FormGroup>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                        {/*<Row>*/}
                                        {/*    <Col>*/}
                                        {/*        <FormGroup>*/}
                                        {/*            <Label>{label.TotalParkingFee}</Label>*/}
                                        {/*            <Input disabled value={currencyFormat(transaction.totalParkingFee, 'Rp')}/>*/}
                                        {/*        </FormGroup>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>*/}
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label size={"lg"}>{label.TotalTransactionAmount}</Label>
                                                    <Input size={"lg"} disabled value={currencyFormat(transaction.totalTransactionAmount, 'Rp')}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <CardHeader>
                                        {label.TransactionStatus}
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <Table hover responsive>
                                                    <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>{label.Name}</th>
                                                        <th>{label.Description}</th>
                                                        <th>{label.Created}</th>
                                                        <th>{label.Updated}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        transactionStatusHistories.map((item, index) => (
                                                            <tr key={item.id}>
                                                                <th scope="row">{(++index)}</th>
                                                                <td>{item.transactionStatus.label}</td>
                                                                <td>{item.description}</td>
                                                                <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                                                <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <SockJsClient
                    url={Global.BASE_URL}
                    topics={[Global.API.LISTEN_UPDATE_CHARGING_INFORMATION+"/"+transaction.id]}
                    autoReconnect={true}
                    debug={Global.DEBUG}
                    heartbeat={5000}
                    onMessage={(chargingInformation) => {
                        // console.log(chargingInformation)
                        if(chargingInformation.transactionStateDto){
                            let transactionState = chargingInformation.transactionStateDto
                            this.setState({transactionState}, () => {
                                if(transactionState.finish){
                                    this.fetchTransactionDetail(transaction.id)
                                }
                            })
                        }
                    }}
                    onConnectFailure={(object)=>{
                        console.error(object)
                    }}
                    ref={ (client) => { this.clientRef = client }} />

            </Page>
        );
    }
}
export default Object.assign(TransactionDetailPage, {ENDPOINT : "/transactionDetail"})
