import React from 'react';
import BasePage from './BasePage';
import axios from 'axios';

import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    CardImg,
    CardTitle,
    Col,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
} from 'reactstrap';
import shoppingBag from 'assets/img/products/shopping-bag.png';
import Page from '../components/Page';
import ItemOption from '../components/Widget/ItemOption';
import Global, {CHARGER_IMAGE_MAX_FILE_SIZE, DEFAULT_LOCATION, MIME_PNG_JPEG} from '../utils/Global';
import {allIsEmpty, imageSelector, isEmpty, isResponseOk} from '../utils/Utilities';
import queryString from 'query-string';
import ConfirmDialog from '../components/modal/ConfirmDialog';
// import InventoryQuantityModal from '../components/modal/InventoryQuantityModal';
import {getData} from '../utils/StorageUtil';
import ImageCropper from '../components/modal/ImageCropper';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import WarningLabel from '../components/Widget/WarningLabel';
import label from '../message/Label'
import CompanySelectionModal from "../components/modal/CompanySelectionModal";
import StationSelectionModal from "../components/modal/StationSelectionModal";
import UpdateChargerStatusModal from "../components/modal/UpdateChargerStatusModal";
import {MdDelete, MdAdd, MdEdit, MdSettings} from "react-icons/md";
import Checkbox from "../components/Checkbox";
import ChargerGunModal from "../components/modal/ChargerGunModal";
import ChargerConfigurationModal from "../components/modal/ChargerConfigurationModal";
import SockJsClient from "react-stomp";
import {IoMdKey} from "react-icons/all";


class ChargerDetailPage extends BasePage{

    constructor(props) {
        super(props);
        let state = this.state
        state.charger = {}
        state.chargerGuns = []
        state.company = {}
        state.station = {}
        state.chargerStatus = {}
        state.categories = []
        state.image  = null
        state.provinces = []
        state.cities = []
        state.districts = []
        state.villages = []
        state.province = {}
        state.city= {}
        state.district = {}
        state.village = {}
        state.chargerIdQuery = queryString.parse(this.props.query).id
        state.category = {}
        state.modalAddConfirm = false
        state.modalDeleteConfirm = false
        state.inventories = []
        state.inventory = null
        state.ascending = true
        state.sortir = 'created'
        state.quantityModal = false
        state.page = queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1
        state.totalPage = 0
        state.totalElement = 0
        state.pageElement = 0
        state.chargerTypes = []
        state.chargerType = {}
        state.companyModal = false ;
        state.stationModal = false ;
        state.company = {}
        state.chargerStatusModal = false
        state.chargerGunModal = false ;
        state.chargerGun = undefined
        state.chargerConfigurationModal = false;
        state.chargerState = {}
        state.validation = {}
        state.price = 0
        this.setState(state)
        this.fetchProvinces((provinces)=>this.setState({provinces:provinces}))

    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchChargerDetail(this.state.chargerIdQuery)
        this.fetchChargerTypes((chargerTypes)=>{
            this.setState({chargerTypes:chargerTypes})
        })
    }

    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            this.setState({
                page:queryString.parse(props.query).page?queryString.parse(props.query).page:1
            })
        }

    }


    confirmSave = () =>{
        let charger = this.state.charger
        let validation = this.state.validation
        let modalAddConfirm = true

        if (isEmpty(charger.name)) {
            validation.name = label.EnterName
            modalAddConfirm = false;
        }
        if (isEmpty(charger.code) || !isEmpty(validation.code)) {
            validation.code = validation.code?validation.code:label.GenerateCode
            modalAddConfirm = false;
        }
        if (isEmpty(charger.label)) {
            validation.label = label.EnterLabel
            modalAddConfirm = false;
        }
        if (isEmpty(charger.chargerType)) {
            validation.chargerType = label.SelectChargeType
            modalAddConfirm = false;
        }
        if (isEmpty(charger.company)) {
            validation.company = label.SelectCompany
            modalAddConfirm = false;
        }
        if (isEmpty(charger.station)) {
            validation.station = label.SelectStation
            modalAddConfirm = false;
        }
        this.setState({
            modalAddConfirm: modalAddConfirm,
            validation:validation
        })

    }

    confirmDelete = () =>{
        this.setState({
            modalDeleteConfirm:true
        })
    }


    saveUpload = () => {
        var formData = new FormData();
        let charger = this.state.charger;

        formData.append("chargerDtoGson", JSON.stringify(charger))
        formData.append("multipartFile1", this.state.imageBlob1);
        formData.append("multipartFile2", this.state.imageBlob2);
        formData.append("multipartFile3", this.state.imageBlob3);
        formData.append("multipartFile4", this.state.imageBlob4);
        this.setState({
            modalAddConfirm:false
        }, () => {
            this.post(Global.API.CHARGER_SAVE_UPLOAD, formData, null, res=>{
                if(res.code===200){
                    this.setState({
                        charger:res.data
                    }, () => {
                        this.showDialog("Success", res.message)
                        this.fetchChargerDetail(this.state.charger.id)
                        // changeParam(this.props, 'id', this.state.charger.id)
                    })
                }
            },  true, true)
        })
    }


    fetchChargerDetail = (id) => {
        if(id!=null){
            this.get(Global.API.CHARGER, {
                params :{
                    id : id
                }
            }, null, response=>{
                if(response.code===200){
                    let charger = response.data
                    // let charger = charger.charger
                    if(!charger.latitude || !charger.longitude){
                        charger.latitude = DEFAULT_LOCATION.latitude
                        charger.longitude = DEFAULT_LOCATION.longitude
                    }
                    let company = charger.company?charger.company:{}
                    let station = charger.station?charger.station:{}
                    let chargerType = charger.chargerType?charger.chargerType:{}
                    let chargerStatus = charger.chargerStatus?charger.chargerStatus:{}
                    let chargerState = charger.chargerState?charger.chargerState:{}

                    let village = charger&&charger.village?charger.village:null
                    let district = charger&&charger.village?charger.village.district:null
                    let city = charger&& charger.village?charger.village.district.city:null
                    let province = charger&&charger.village?charger.village.district.city.province:null

                    this.setState({
                        charger:charger,
                        company:company,
                        station:station,
                        chargerType:chargerType,
                        chargerStatus:chargerStatus,
                        chargerState:chargerState,
                        taskCategory:response.data.taskCategory,
                        image1:charger.imageLink1,
                        image2:charger.imageLink2,
                        image3:charger.imageLink3,
                        image4:charger.imageLink4,
                        village:village,
                        district:district,
                        city:city,
                        province:province,
                    }, () => {
                        if(this.state.village){
                            this.fetchCities(this.state.province.id, cities=>{
                                this.setState({
                                    cities:cities
                                })
                            })
                        }
                        if(this.state.city){
                            this.fetchDistricts(this.state.city.id, districts =>{
                                this.setState({
                                    districts:districts
                                })
                            })
                        }
                        if(this.state.district){
                            this.fetchVillages(this.state.district.id, villages=>{
                                this.setState({
                                    villages:villages
                                })
                            })
                        }
                        this.fetchChargerGun(charger.id)
                    })
                }else{
                    this.props.history.goBack();
                }
            }, true, true)
        }
    }

    fetchChargerGun(chargerId){
        if(!chargerId){
            return
        }
        this.get(Global.API.CHARGER_GUNS, {
            params :{
                sortir:"position",
                ascending:"true",
                chargerId : chargerId
            }
        },null, (res)=>{
            if(isResponseOk(res)){
                let chargerGuns = res.data
                this.setState({chargerGuns})

            }
        }, false, false)

    }

    async updateChargerGunEnabled(chargerGun, enabled){
        let form = new FormData()
        form.append("chargerGunId", chargerGun.id)
        form.append("enabled", enabled)
        let response = await this.asyncPost(Global.API.CHARGER_GUN_UPDATE_ENABLED, form,null, true, true);
        if(isResponseOk(response)){
            return response.data
        }
        return chargerGun;
    }
    async deleteChargerGun(chargerGun){
        let params = {
            id : chargerGun.id
        }
        let response = await this.asyncGet(Global.API.CHARGER_GUN_DELETE, {params},null, true, true);
        if(isResponseOk(response)){
            return response.data
        }
        return chargerGun;
    }

    closeDialog = () =>{
        this.setState({
            modalAddConfirm:false,
            modalDeleteConfirm:false,
        })
    }

    generateCode(){
        let {charger, validation} = this.state
        if(!charger){
            charger = {}
        }
        this.get(Global.API.STATION_GENERATE_CODE, {
            params:{
                chargerId:charger.id
            }
        }, null, response=>{
            if(response.code===200){
                charger.code = response.data
                validation.code = undefined
                this.setState({charger, validation})
            }
        }, true, true)
    }
    validateCode(){
        let {charger, validation} = this.state
        if(!charger || !charger.code){
            return;
        }
        this.get(Global.API.CHARGER_VALIDATE_CODE, {
            params:{
                chargerId:charger.id,
                code:charger.code
            }
        }, null, response=>{
            if(response.code===200){
                let valid = response.data
                if(!valid){
                    validation.code = label.CodeAlreadyBeenUsed
                }else{
                    validation.code = undefined
                }
                this.setState({validation})
            }
        }, false, true)
    }

    render() {
        var ids = [];
        var labels = [];

        let configuration = getData(Global.CONFIGURATION)
        let aspect = 4/4
        if(configuration.chargerImageDimensionWidth && configuration.chargerImageDimensionHeight){
            aspect = configuration.chargerImageDimensionWidth/configuration.chargerImageDimensionHeight
        }
        let chargerTypes = this.state.chargerTypes?this.state.chargerTypes:[]
        let chargerType = this.state.chargerType?this.state.chargerType:{}
        let {companyModal, stationModal, charger, company, station, chargerStatus, chargerStatusModal,
            chargerGuns, chargerGunModal, chargerGun, chargerConfigurationModal, chargerState, validation} = this.state
        // let charger = this.state.charger?this.state.charger:{}



        return (
            <Page
                title="Charger Form"
                breadcrumbs={[{ name: 'charger form', active: true }]}>
                {super.render()}
                <ConfirmDialog
                    showing={this.state.modalAddConfirm}
                    title={label.UpdateConfirmation}
                    message="Do you want to update this charger item to your list ?"
                    okCallback={this.saveUpload}
                    cancelCallback={this.closeDialog}/>
                <ConfirmDialog
                    showing={this.state.modalDeleteConfirm}
                    title={label.DeleteConfirmationTitle}
                    message="Do you want to delete this charger item from your list ?"
                    okCallback={this.delete}
                    cancelCallback={this.closeDialog}/>
                <CompanySelectionModal
                    showing={companyModal}
                    company={company}
                    onClose={()=>{
                        company = {}
                        charger.company = company
                        this.setState({companyModal:false, company, charger})
                    }}
                    onSelect={(company)=>{
                        company = company
                        charger.company = company
                        validation.company = undefined
                        this.setState({companyModal:false, company:company, charger, validation})
                    }}
                />
                <StationSelectionModal
                    showing={stationModal}
                    station={station}
                    onClose={()=>{
                        station = {}
                        charger.station = station
                        this.setState({stationModal:false, station, charger})
                    }}
                    onSelect={(station)=>{
                        station = station
                        charger.station = station
                        validation.station = undefined
                        this.setState({stationModal:false, station, charger, validation})
                    }}
                />
                <UpdateChargerStatusModal
                    modal={chargerStatusModal}
                    charger={charger}
                    okCallback={(chargerStatus)=>{
                        charger.chargerStatus = chargerStatus
                        this.setState({chargerStatus:chargerStatus, charger:charger, chargerStatusModal:false})
                    }} closeCallback={()=>{
                        this.setState({chargerStatusModal:false})
                    }}/>
                <ChargerGunModal
                    modal={chargerGunModal}
                    closeCallback={()=>{
                        this.setState({chargerGun:undefined, chargerGunModal:false})
                    }}
                    okCallback={()=>{
                        this.setState({chargerGun:undefined, chargerGunModal:false}, () => {
                            this.fetchChargerGun(charger.id)
                        })
                    }}
                    chargerOption={false}
                    charger={charger}
                    chargerGun={chargerGun}/>
                <ChargerConfigurationModal charger={charger} showing={chargerConfigurationModal} onClose={()=>{
                    this.setState({
                        chargerConfigurationModal:false
                    })
                }}/>
                <Row key={1}>
                    <Col>
                        <Card className="mb-6">
                            <CardHeader>{label.Charger}</CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={3}>
                                        <Card>
                                            <CardImg top src={
                                                this.state.image1!=null?this.state.image1:shoppingBag} onClick={e=>{
                                                imageSelector(file=>{
                                                    this.setState({
                                                        image1:file,
                                                        cropperModal1:true
                                                    })
                                                }, MIME_PNG_JPEG, CHARGER_IMAGE_MAX_FILE_SIZE).click()
                                            }}
                                            onError={(elm)=>this.defaultImage(elm, shoppingBag)}/>
                                            <CardBody>
                                                <CardTitle>Charger Image</CardTitle>
                                                <Row>
                                                    <Col>
                                                        <WarningLabel message={"*Use PNG or Jpeg Format / Max "+CHARGER_IMAGE_MAX_FILE_SIZE+" Kb"}/>
                                                    </Col>
                                                </Row>
                                                <br/>
                                                <Row>
                                                    <Col>
                                                        <ButtonGroup className="float-left">
                                                            <Button color="danger" onClick={e => (
                                                                this.setState({
                                                                    charger:null,
                                                                    image1:null
                                                                })
                                                            )}>{label.Remove}</Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                    <Col>
                                                        <ButtonGroup className="float-right">
                                                            <Button color="primary" onClick={e=>{
                                                                imageSelector(file=>{
                                                                    this.setState({
                                                                        image1:file,
                                                                        cropperModal1:true
                                                                    })
                                                                }, MIME_PNG_JPEG, CHARGER_IMAGE_MAX_FILE_SIZE).click()
                                                            }}>{label.Upload}</Button>

                                                        </ButtonGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={3}>
                                        <Card>
                                            <CardImg top src={
                                                this.state.image2!=null?this.state.image2:shoppingBag} onClick={e=>{
                                                imageSelector(file=>{
                                                    this.setState({
                                                        image2:file,
                                                        cropperModal2:true
                                                    })
                                                }, MIME_PNG_JPEG, CHARGER_IMAGE_MAX_FILE_SIZE).click()
                                            }}
                                            onError={(elm)=>this.defaultImage(elm, shoppingBag)}/>
                                            <CardBody>
                                                <CardTitle>Charger Image</CardTitle>
                                                <Row>
                                                    <Col>
                                                        <WarningLabel message={"*Use PNG or Jpeg Format / Max "+CHARGER_IMAGE_MAX_FILE_SIZE+" Kb"}/>
                                                    </Col>
                                                </Row>
                                                <br/>
                                                <Row>
                                                    <Col>
                                                        <ButtonGroup className="float-left">
                                                            <Button color="danger" onClick={e => (
                                                                this.setState({
                                                                    charger:null,
                                                                    image2:null
                                                                })
                                                            )}>{label.Remove}</Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                    <Col>
                                                        <ButtonGroup className="float-right">
                                                            <Button color="primary" onClick={e=>{
                                                                imageSelector(file=>{
                                                                    this.setState({
                                                                        image2:file,
                                                                        cropperModal2:true
                                                                    })
                                                                }, MIME_PNG_JPEG, CHARGER_IMAGE_MAX_FILE_SIZE).click()
                                                            }}>{label.Upload}</Button>

                                                        </ButtonGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={3}>
                                        <Card>
                                            <CardImg top src={
                                                this.state.image3!=null?this.state.image3:shoppingBag} onClick={e=>{
                                                imageSelector(file=>{
                                                    this.setState({
                                                        image3:file,
                                                        cropperModal3:true
                                                    })
                                                }, MIME_PNG_JPEG, CHARGER_IMAGE_MAX_FILE_SIZE).click()
                                            }}
                                                     onError={(elm)=>this.defaultImage(elm, shoppingBag)}/>
                                            <CardBody>
                                                <CardTitle>Charger Image</CardTitle>
                                                <Row>
                                                    <Col>
                                                        <WarningLabel message={"*Use PNG or Jpeg Format / Max "+CHARGER_IMAGE_MAX_FILE_SIZE+" Kb"}/>
                                                    </Col>
                                                </Row>
                                                <br/>
                                                <Row>
                                                    <Col>
                                                        <ButtonGroup className="float-left">
                                                            <Button color="danger" onClick={e => (
                                                                this.setState({
                                                                    charger:null,
                                                                    image3:null
                                                                })
                                                            )}>{label.Remove}</Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                    <Col>
                                                        <ButtonGroup className="float-right">
                                                            <Button color="primary" onClick={e=>{
                                                                imageSelector(file=>{
                                                                    this.setState({
                                                                        image3:file,
                                                                        cropperModal3:true
                                                                    })
                                                                }, MIME_PNG_JPEG, CHARGER_IMAGE_MAX_FILE_SIZE).click()
                                                            }}>{label.Upload}</Button>

                                                        </ButtonGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={3}>
                                        <Card>
                                            <CardImg top src={
                                                this.state.image4!=null?this.state.image4:shoppingBag} onClick={e=>{
                                                imageSelector(file=>{
                                                    this.setState({
                                                        image4:file,
                                                        cropperModal4:true
                                                    })
                                                }, MIME_PNG_JPEG, CHARGER_IMAGE_MAX_FILE_SIZE).click()
                                            }}
                                                     onError={(elm)=>this.defaultImage(elm, shoppingBag)}/>
                                            <CardBody>
                                                <CardTitle>Charger Image</CardTitle>
                                                <Row>
                                                    <Col>
                                                        <WarningLabel message={"*Use PNG or Jpeg Format / Max "+CHARGER_IMAGE_MAX_FILE_SIZE+" Kb"}/>
                                                    </Col>
                                                </Row>
                                                <br/>
                                                <Row>
                                                    <Col>
                                                        <ButtonGroup className="float-left">
                                                            <Button color="danger" onClick={e => (
                                                                this.setState({
                                                                    charger:null,
                                                                    image4:null
                                                                })
                                                            )}>{label.Remove}</Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                    <Col>
                                                        <ButtonGroup className="float-right">
                                                            <Button color="primary" onClick={e=>{
                                                                imageSelector(file=>{
                                                                    this.setState({
                                                                        image4:file,
                                                                        cropperModal4:true
                                                                    })
                                                                }, MIME_PNG_JPEG, CHARGER_IMAGE_MAX_FILE_SIZE).click()
                                                            }}>{label.Upload}</Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <CardHeader>{label.Information}</CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="name">{label.Name}</Label>
                                                            <Input
                                                                type="text"
                                                                name="name"
                                                                value={(charger)?charger.name:""}
                                                                onChange={(e) =>{
                                                                    e.preventDefault()
                                                                    charger.name = e.target.value
                                                                    validation.name = undefined
                                                                    this.setState({
                                                                        charger, validation
                                                                    })

                                                                }}
                                                                placeholder={label.EnterName}
                                                            />
                                                            <WarningLabel show={validation.name} message={validation.name}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label for="name">{label.Label}</Label>
                                                            <Input
                                                                type="text"
                                                                name="label"
                                                                value={(charger)?charger.label:""}
                                                                onChange={(e) =>{
                                                                    e.preventDefault()
                                                                    charger.label = e.target.value
                                                                    validation.label = undefined
                                                                    this.setState({
                                                                        charger,validation
                                                                    })

                                                                }}
                                                                placeholder={label.EnterLabel}
                                                            />
                                                            <WarningLabel show={validation.label} message={validation.label}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <Label for="code">{label.Code}</Label>
                                                            <Input
                                                                type="text"
                                                                name="code"
                                                                value={(charger)?charger.code:""}
                                                                onChange={(e) =>{
                                                                    e.preventDefault()
                                                                    charger.code = e.target.value
                                                                    validation.code = undefined
                                                                    this.setState({
                                                                        charger, validation
                                                                    }, () => {
                                                                        this.validateCode()
                                                                    })

                                                                }}
                                                            />
                                                            <WarningLabel show={validation.code} message={validation.code}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={1}>
                                                        <FormGroup>
                                                            <Label>&nbsp;</Label>
                                                            <Button block onClick={(e)=>{
                                                                this.generateCode()
                                                            }}><IoMdKey/></Button>
                                                        </FormGroup>
                                                    </Col>
                                                    {/*<Col md={2}>*/}
                                                    {/*    <FormGroup>*/}
                                                    {/*        <Label for="socAvailable">{label.SoCAvailable}</Label>*/}
                                                    {/*        <br/>*/}
                                                    {/*        <Checkbox*/}
                                                    {/*            checked={charger.socAvailable}*/}
                                                    {/*            onChange={(checked) => {*/}
                                                    {/*                charger.socAvailable = checked*/}
                                                    {/*                this.setState({*/}
                                                    {/*                    charger*/}
                                                    {/*                })*/}
                                                    {/*            }}*/}
                                                    {/*        />*/}
                                                    {/*    </FormGroup>*/}
                                                    {/*</Col>*/}
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <ItemOption
                                                                title={label.SelectChargeType}
                                                                objects={chargerTypes}
                                                                fieldForLabel={"type"}
                                                                default={!allIsEmpty(chargerType)?chargerType.id:null}
                                                                callback={(chargerType)=>{
                                                                    if(chargerType){
                                                                        validation.chargerType = undefined
                                                                        charger.chargerType = chargerType
                                                                        this.setState({
                                                                            charger,chargerType,validation
                                                                        })
                                                                    }else{
                                                                        this.setState({
                                                                            chargerType:undefined,
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                            <WarningLabel show={validation.chargerType} message={validation.chargerType}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="name">{label.Company}</Label>
                                                            <Input
                                                                style={{cursor:'pointer'}}
                                                                type="text"
                                                                name="company"
                                                                value={(company)?company.fullName:""}
                                                                onClick={()=>{
                                                                    this.setState({companyModal:true})
                                                                }}
                                                                readOnly={true}
                                                                placeholder={label.SelectCompany}
                                                            />
                                                            <WarningLabel show={validation.company} message={validation.company}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="station">{label.Station}</Label>
                                                            <Input
                                                                style={{cursor:'pointer'}}
                                                                type="text"
                                                                name="station"
                                                                value={(station)?station.name:""}
                                                                onClick={()=>{
                                                                    this.setState({stationModal:true})
                                                                }}
                                                                readOnly={true}
                                                                placeholder={label.SelectStation}
                                                            />
                                                            <WarningLabel show={validation.station} message={validation.station}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="station">{label.Price}</Label>
                                                            <Input
                                                                type="text"
                                                                name="price"
                                                                value={(charger)?charger.amount:""}
                                                                onChange={(e) =>{
                                                                    e.preventDefault()
                                                                    charger.amount = e.target.value
                                                                    validation.amount = undefined
                                                                    this.setState({
                                                                        charger, validation
                                                                    })

                                                                }}
                                                                placeholder={label.Price}
                                                            />
                                                            <WarningLabel show={validation.amount} message={validation.amount}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="station">{label.ChargingDurationInMinute}</Label>
                                                            <Input
                                                                type="text"
                                                                name="price"
                                                                value={(charger)?charger.chargingDuration:""}
                                                                onChange={(e) =>{
                                                                    e.preventDefault()
                                                                    charger.chargingDuration = e.target.value
                                                                    validation.chargingDuration = undefined
                                                                    this.setState({
                                                                        charger, validation
                                                                    })

                                                                }}
                                                                placeholder={label.ChargingDurationInMinute}
                                                            />
                                                            <WarningLabel show={validation.chargingDuration} message={validation.chargingDuration}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col></Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="name">{label.Description}</Label>
                                                            <Input
                                                                type="textarea"
                                                                rows={4}
                                                                name="description"
                                                                value={(charger)?charger.description:""}
                                                                onChange={(e) =>{
                                                                    e.preventDefault()
                                                                    if(charger!=null){
                                                                        charger.description = e.target.value
                                                                        this.setState({
                                                                            charger:charger
                                                                        })
                                                                    }
                                                                }}
                                                                placeholder={label.EnterDescription}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col md={8}>
                                                        {label.ChargerGun}
                                                    </Col>
                                                    <Col md={4}>
                                                        {
                                                            charger.id&&(
                                                                <ButtonGroup className="float-right">
                                                                    <Button color="primary" onClick={e=>{
                                                                        this.setState({chargerGunModal:true})
                                                                    }}><MdAdd/></Button>
                                                                </ButtonGroup>
                                                            )
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <Table responsive hover>
                                                            <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>{label.Code}</th>
                                                                <th>{label.Type}</th>
                                                                <th>{label.Label}</th>
                                                                <th>{label.Position}</th>
                                                                <th>{label.Enabled}</th>
                                                                <th>{label.Status}</th>
                                                                <th>{label.Remove}</th>
                                                                <th>{label.Edit}</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                chargerGuns.map((item, index)=>(
                                                                    <tr key={index}>
                                                                        <th scope="row">{((this.state.page-1)* this.state.pageElement)+(index+1)}</th>
                                                                        <td>{item.code}</td>
                                                                        <td>{item.chargerGunType?item.chargerGunType.type:""}</td>
                                                                        <td>{item.label}</td>
                                                                        <td>{item.position}</td>
                                                                        <td>
                                                                            <Checkbox
                                                                                checked={item.enabled}
                                                                                onChange={(checked) => {
                                                                                    if(checked!=item.enabled){
                                                                                        this.openConfirmDialog(label.Confirmation, label.UpdateConfirmationMessage,
                                                                            async ()=>{
                                                                                                let chargerGun = await this.updateChargerGunEnabled(item, checked)
                                                                                                chargerGuns[index] = chargerGun
                                                                                                this.setState({chargerGuns})
                                                                                            },
                                                                          ()=>{
                                                                                                this.forceUpdate()
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td>

                                                                            {item.chargerGunState ?

                                                                                <Button
                                                                                    style={{background:item.chargerGunState.color}}
                                                                                    disabled={true}
                                                                                    size={'md'}>{item.chargerGunState.label}</Button>

                                                                                :""}
                                                                        </td>
                                                                        <td>
                                                                            <Button
                                                                                color={"danger"}
                                                                                onClick={() => {
                                                                                    this.openConfirmDialog(label.DeleteConfirmationTitle, label.DeleteConfirmationMessage,
                                                                                        async ()=>{
                                                                                            await this.deleteChargerGun(item)
                                                                                            chargerGuns.splice(index, 1)
                                                                                            this.setState({chargerGuns})
                                                                                        }
                                                                                )}}>
                                                                                <MdDelete/>
                                                                            </Button>
                                                                        </td>
                                                                        <td>
                                                                            <Button
                                                                                color={"primary"}
                                                                                onClick={() => this.setState({chargerGunModal:true, chargerGun:item})}>
                                                                                <MdEdit/>
                                                                            </Button>
                                                                            <SockJsClient
                                                                                url={Global.BASE_URL}
                                                                                topics={[Global.API.LISTEN_UPDATE_CHARGER_GUN_STATE+"/"+item.code]}
                                                                                autoReconnect={true}
                                                                                debug={Global.DEBUG}
                                                                                heartbeat={5000}
                                                                                onMessage={(chargerGunState) => {
                                                                                    // console.log("-> update sockjs")
                                                                                    chargerGuns[index].chargerGunState = chargerGunState
                                                                                    this.setState({chargerGuns})
                                                                                }}
                                                                                onConnectFailure={(object)=>{
                                                                                    console.error(object)
                                                                                }}
                                                                                ref={ (client) => { this.clientRef = client }}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                            </tbody>
                                                        </Table>

                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <ButtonGroup className="float-left">
                                            <Button color="danger" onClick={e => (
                                                this.setState({
                                                    charger:{}
                                                }, () => {
                                                    this.props.history.goBack();
                                                })
                                            )}>{label.Back}</Button>
                                        </ButtonGroup>
                                    </Col>
                                    <Col>
                                        <ButtonGroup className="float-right">
                                            <Button color="primary" onClick={e=>{
                                                e.preventDefault()
                                                this.confirmSave()
                                            }}> {
                                                (this.state.charger!=null && this.state.charger.id!=null)?label.Update:label.Create
                                            } </Button>

                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <SockJsClient
                    url={Global.BASE_URL}
                    topics={[Global.API.LISTEN_UPDATE_CHARGER_STATE+"/"+charger.code]}
                    autoReconnect={true}
                    debug={Global.DEBUG}
                    heartbeat={5000}
                    onMessage={(chargerState) => {
                        this.setState({chargerState})
                    }}
                    onConnectFailure={(object)=>{
                        console.error(object)
                    }}
                    ref={ (client) => { this.clientRef = client }} />

                <ImageCropper src={this.state.image1} aspect={aspect} show={this.state.cropperModal1} callback={file=>{
                    axios({
                        url: file,
                        method: 'GET',
                        responseType: 'blob', // important
                    }).then((response) => {
                        var fileBlob = response.data ;
                        this.setState({
                            image1:file,
                            cropperModal1:false,
                            imageBlob1:fileBlob,
                        })
                    })
                }} cancelCallback={()=>{
                    this.setState({
                        cropperModal1:false,
                        image1:charger.imageLink1,
                        imageBlob1:null,
                    })
                }}/>
                <ImageCropper src={this.state.image2} aspect={aspect} show={this.state.cropperModal2} callback={file=>{
                    axios({
                        url: file,
                        method: 'GET',
                        responseType: 'blob', // important
                    }).then((response) => {
                        var fileBlob = response.data ;
                        this.setState({
                            image2:file,
                            cropperModal2:false,
                            imageBlob2:fileBlob,
                        })
                    })
                }} cancelCallback={()=>{
                    this.setState({
                        cropperModal2:false,
                        image2:charger.imageLink2,
                        imageBlob2:null,
                    })
                }}/>
                <ImageCropper src={this.state.image3} aspect={aspect} show={this.state.cropperModal3} callback={file=>{
                    axios({
                        url: file,
                        method: 'GET',
                        responseType: 'blob', // important
                    }).then((response) => {
                        var fileBlob = response.data ;
                        this.setState({
                            image3:file,
                            cropperModal3:false,
                            imageBlob3:fileBlob,
                        })
                    })
                }} cancelCallback={()=>{
                    this.setState({
                        cropperModal3:false,
                        image3:charger.imageLink3,
                        imageBlob3:null,
                    })
                }}/>
                <ImageCropper src={this.state.image4} aspect={aspect} show={this.state.cropperModal4} callback={file=>{
                    axios({
                        url: file,
                        method: 'GET',
                        responseType: 'blob', // important
                    }).then((response) => {
                        var fileBlob = response.data ;
                        this.setState({
                            image4:file,
                            cropperModal4:false,
                            imageBlob4:fileBlob,
                        })
                    })
                }} cancelCallback={()=>{
                    this.setState({
                        cropperModal4:false,
                        image4:charger.imageLink4,
                        imageBlob4:null,
                    })
                }}/>

            </Page>
        );
    }


}
export default Object.assign(ChargerDetailPage, {ENDPOINT : "/chargerDetail"})
