import PropTypes from 'prop-types';
import React from 'react';
import {Button, Form, FormGroup, Input, Label} from 'reactstrap';
import {AuthConsumer} from '../utils/AuthContext';
import Global from '../utils/Global';
import BaseComponent from './BaseComponent';
import {getData, storeData} from '../utils/StorageUtil';
import Img from 'react-image';
import catchadealLogo from 'assets/img/logo/alessa_logo.png';
import label from '../message/Label'
import {allIsEmpty, isEmpty, isValidEmail} from "../utils/Utilities";
import WarningLabel from "./Widget/WarningLabel";

class AuthForm extends BaseComponent {

  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
    let state = this.state
    state.username = Global.DEV_LOGIN_USERNAME
    state.password = Global.DEV_LOGIN_PASSWORD
    state.configuration = getData(Global.CONFIGURATION)
    state.configurationAdmin = getData(Global.CONFIGURATION_ADMIN)
    state.message = this.props.message
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchAppSetting()
    this.fetchConfigurationAdmin()
  }

  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };

  handleSubmit = (event, authProps) => {
    event.preventDefault();
    let {username, password} = this.state
    if(isEmpty(username) || isEmpty(password)){
      this.setState({
        message : label.PleaseCompleteTheFields
      })
      return ;
    }
    if(this.isLogin){
      this.post(Global.API.LOGIN, null, {
        params:{
          username:username,
          password:password
        }
      }, response=>{
        if(response.code===200){
          const admin = response.data ;
          storeData(Global.AUTHORIZATION, response.data.authorization)
          authProps.login(admin)
        }else{
          console.error(response.message)
          this.setState({
            message :response.message
          }, () => {
            this.forceUpdate()
          })
        }
      }, true, false)
    }
  };

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return label.LOGIN;
    }

    if (!buttonText && this.isSignup) {
      return label.SIGNUP;
    }

    return buttonText;
  }

  fetchAppSetting=()=>{
    this.get(Global.API.CONFIGURATION_CURRENT_ACTIVE, null, null, response=>{
      if(response.code===200){
        let configuration = response.data
        storeData(Global.CONFIGURATION, configuration)
        this.setState({
          configuration:configuration
        })
      }
    })
  }

  fetchConfigurationAdmin=()=>{
    this.get(Global.API.CONFIGURATION_ADMIN, null, null, response=>{
      if(response.code===200){
        let configurationAdmin = response.data
        storeData(Global.CONFIGURATION_ADMIN, configurationAdmin)
        this.setState({
          configurationAdmin:configurationAdmin
        })
      }
    })
  }


  render() {
    let {username, password} = this.state
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
    } = this.props;

    let configuration = this.state.configuration
    if(!configuration){
      configuration = {}
    }
    let {message} = this.state


    const baseRender = super.render();
    return (
          <AuthConsumer>
            {
              (authProps) => (
                  <div>
                    {baseRender}
                    {showLogo && (
                        <div className="text-center pb-4" style={{marginTop:'50px', paddingTop:'20px'}}>
                          <Img
                              src={catchadealLogo}
                              className="rounded"
                              style={{width:'70%', height:'30%'}}
                              alt="logo"
                              onClick={onLogoClick}
                          />
                        </div>
                    )}
                    <Form onSubmit={e=>this.handleSubmit(e,authProps)}>
                      <br/>
                      <FormGroup>
                        <Label for={usernameLabel}>{usernameLabel}</Label>
                        <Input {...usernameInputProps} value={username} onChange={e=>{this.setState({username:e.target.value})}}/>
                      </FormGroup>
                      <FormGroup>
                        <Label for={passwordLabel}>{passwordLabel}</Label>
                        <Input {...passwordInputProps} value={password} onChange={e=>{this.setState({password:e.target.value})}}  onKeyDown={e=>{
                          // alert(e.key)
                          if(e.key==='Enter'){
                            e.preventDefault()
                            this.handleSubmit(e, authProps)
                          }
                        }} />
                      </FormGroup>
                      {this.isSignup && (
                          <FormGroup>
                            <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
                            <Input {...confirmPasswordInputProps} value={this.state.password} onChange={e=>{this.setState({password:e.value})}}/>
                          </FormGroup>
                      )}
                      <hr />
                      {message&&(
                          <>
                            <WarningLabel style={{textAlign:'center', fontSize:'14px'}} show={message} message={message}/>
                            <hr />
                          </>
                      )}
                      <Button
                          size="lg"
                          block
                          color={"secondary"}
                          onClick={e=>this.handleSubmit(e, authProps)}>
                        {this.renderButtonText()}
                      </Button>
                      {children}
                    </Form>

                  </div>
              )
            }
          </AuthConsumer>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
  message:PropTypes.string,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: label.Username,
  usernameInputProps: {
    type: 'username',
    placeholder: label.EnterUsername,
  },
  passwordLabel: label.Password,
  passwordInputProps: {
    type: 'password',
    placeholder: label.EnterPassword,
  },
  confirmPasswordLabel: label.ConfirmPassword,
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: label.EnterConfirmPassword,
  },
  onLogoClick: () => {},
};

export default AuthForm;
