import BasePage from './BasePage';
import React from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Label,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import {
    changeParam2,
    changeParam3,
    currencyFormat,
    isEmpty,
    navigatePage,
    parseDate,
    sortirMap
} from '../utils/Utilities';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {IoMdSettings} from 'react-icons/io';
import SearchInput from '../components/SearchInput';
import Global, {CURRENCY_SYMBOL, DD_MM_YYYY_HH_MM_SS, TRANSACTION_STATUS_COLOR} from '../utils/Global';
import Pagination from '../components/Pagination';
import queryString from 'query-string';
import SockJsClient from 'react-stomp';
import label from '../message/Label'
import {FiArrowDownCircle, FiArrowUpCircle} from "react-icons/fi";
import DateInput from "../components/Widget/DateInput";
import ItemOption from "../components/Widget/ItemOption";
import {MdRestore} from "react-icons/md";
import {FaFileExcel} from "react-icons/fa";
import Page from "../components/Page";
import ProductModal from "../components/modal/ProductModal";


class TransactionPage extends BasePage {

    constructor(props) {
        super(props);
        this.state = {
            ascending: false,
            sortir: 'ja.updated',
            page: queryString.parse(this.props.query).page ? queryString.parse(this.props.query).page : 1,
            key: queryString.parse(this.props.query).tab ? queryString.parse(this.props.query).tab : label.All,
            totalPage: 0,
            totalElement: 0,
            pageElement: 0,
            createdStartDate: null,
            createdEndDate: null,
            updatedStartDate: null,
            updatedEndDate: null,
            transactionStatuses: [],
            transactions: [],
            products: [],
            product: {},
            modelModal: false,
            transactionStatus: null,
            transactionStatusId: undefined,
            stations:[],
            chargers:[],
            station:{},
            charger:{},
            inputSearch: queryString.parse(this.props.query).search ? queryString.parse(this.props.query).search : "",
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchAllStatus()
        this.fetchModels()
        this.fetchStations()
        if(this.state.station&&this.state.station.id){
            this.fetchChargers(this.state.station)
        }

    }

    componentWillReceiveProps(props, nextContext) {
        if (props != this.props) {
            let page = queryString.parse(props.query).page ? queryString.parse(props.query).page : 1
            let key = queryString.parse(props.query).tab ? queryString.parse(props.query).tab : label.All
            let inputSearch = queryString.parse(props.query).search ? queryString.parse(props.query).search : ""
            let transactionStatuses = this.state.transactionStatuses?this.state.transactionStatuses:[]
            let transactionStatusId = null
            if (key === label.All) {
                transactionStatusId = null
            } else {
                transactionStatuses.map((transactionStatus)=>{
                    if (key == transactionStatus.sortir) {
                        transactionStatusId = transactionStatus.id
                    }
                })
            }
            this.setState({
                page: page,
                key: key,
                inputSearch: inputSearch,
                transactionStatusId: transactionStatusId
            }, () => {
                this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, true)
            })
        }
    }


    fetchStations = () =>{
        this.get(Global.API.STATIONS, {
            params:{
                ascending:true,
                sortir:"jc.name"
            }
        }, null, res=>{
            if(res.code === 200){
                this.setState({
                    stations : res.data,
                })
            }
        },false, true);

    }
    fetchChargers = (station) =>{
        this.get(Global.API.CHARGERS, {
            params:{
                ascending:true,
                sortir:"jt.created",
                stationId:station.id
            }
        }, null, response=>{
            if(response.code===200){
                let chargers = response.data
                this.setState({
                    chargers : chargers
                })
            }
        }, false, true)
    }

    fetchAllStatus = () => {
        let {key} = this.state
        this.get(Global.API.TRANSACTION_STATUSES, null, null, response => {
            if (response.code === 200) {
                let transactionStatuses = response.data
                this.fetchBadgeCount(undefined)
                transactionStatuses.forEach((item, index) => {
                    this.fetchBadgeCount(item.id)
                })
                let transactionStatusId = null
                transactionStatuses.map((transactionStatus)=>{
                    if (key == transactionStatus.sortir) {
                        transactionStatusId = transactionStatus.id
                    }
                })
                this.setState({
                    transactionStatusId:transactionStatusId,
                    transactionStatuses: transactionStatuses
                }, () => {
                    this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, true)
                })
            }
        }, true, true)
    }

    fetchModels = () => {
        this.get(Global.API.PRODUCTS, null, null, response => {
            if (response.code === 200) {
                this.setState({
                    products: response.data
                })
            }
        }, true, true)
    }

    fetchAll = (ascending, sortir, search, progressing, excelMode) => {
        let {transactionStatusId, station, charger} = this.state
        let params = {
            transactionStatusId: transactionStatusId,
            ascending: ascending,
            sortir: sortir,
            search: search,
            stationId : station.id,
            chargerId : charger.id,
            page: this.state.page - 1,
            createdStartDate: this.state.createdStartDate,
            createdEndDate: this.state.createdEndDate,
            updatedStartDate: this.state.updatedStartDate,
            updatedEndDate: this.state.updatedEndDate,
            productId: this.state.product.id,
            excelMode: excelMode
        }
        if (excelMode) {
            this.asyncDownloadGet(Global.API.TRANSACTIONS, {params: params}, null, true);
            return
        }
        this.get(Global.API.TRANSACTIONS, {params: params}, null, res => {
            if (res.code === 200) {
                this.setState({
                    transactions: res.data,
                    totalPage: res.totalPage,
                    totalElement: res.totalElement,
                    ['size-' + transactionStatusId ? transactionStatusId : "all"]: res.totalElement,
                    pageElement: res.pageElement,
                })
            }
        }, progressing, true);
    }

    refreshTransactions = (ascending, sortir, progressing) => (
        this.setState({
            ascending: ascending,
            sortir: sortir,
        }, () => {
            this.fetchAll(ascending, sortir, this.state.inputSearch, progressing)
        })
    )

    resetFilter() {
        this.setState({
            page: 1,
            totalPage: 0,
            totalElement: 0,
            pageElement: 0,
            createdStartDate: null,
            createdEndDate: null,
            updatedStartDate: null,
            updatedEndDate: null,
            transactions: [],
            transactionStatus: null,
            transactionStatusId: null,
            product: {},
            station: {},
            charger: {},
            inputSearch: "",
        }, () => {
            // navigatePage(this.props, 1)
            changeParam3(this.props, 'page', 1, 'tab', this.state.key, 'search', this.state.inputSearch)
        })
    }


    render() {
        let totalElement = this.state.totalElement
        let totalPage = this.state.totalPage
        let {stations, station, chargers, charger, products, product, modelModal} = this.state

        return (
            <Page
                title={label.TransactionList}
                breadcrumbs={[{name: label.TransactionList, active: true}]}
                className="TablePage"
            >
                {super.render()}
                <ProductModal
                    show={modelModal}
                    product={product}
                    onClickOk={() => {
                        this.setState({modelModal: false})
                    }}/>
                <Row key={1}>
                    <Col>
                        <Card>
                            <CardHeader style={{cursor: 'pointer'}} onClick={event => {
                                this.setState({
                                    userFilterExpand: !this.state.userFilterExpand
                                })
                            }}>
                                {
                                    (this.state.userFilterExpand) ? (<FiArrowUpCircle/>) : ((<FiArrowDownCircle/>))
                                }
                                &nbsp;{label.AdvanceFilter} ({label.ClickToExpandFilterContent})
                            </CardHeader>
                            <Collapse isOpen={this.state.userFilterExpand}>
                                <CardHeader>
                                    <Row>
                                        <Col md={3}>
                                            <Label for="createdStartDate">{label.CreatedStartDate}</Label>
                                            <DateInput
                                                id="createdStartDate"
                                                value={this.state.createdStartDate}
                                                maxdate={this.state.createdEndDate}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    this.setState({
                                                        createdStartDate: value ? value : null
                                                    })
                                                }}
                                                placeholder={label.EnterStartDate}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label for="createdEndDate">{label.CreatedEndDate}</Label>
                                            <DateInput
                                                id="createdEndDate"
                                                mindate={this.state.createdStartDate}
                                                value={this.state.createdEndDate}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    this.setState({
                                                        createdEndDate: value ? value : null
                                                    })
                                                }}
                                                placeholder={label.EnterEndDate}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label for="updatedStartDate">{label.UpdatedStartDate}</Label>
                                            <DateInput
                                                id="updatedStartDate"
                                                value={this.state.updatedStartDate}
                                                maxdate={this.state.updatedEndDate}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    this.setState({
                                                        updatedStartDate: value ? value : null
                                                    })
                                                }}
                                                placeholder={label.EnterStartDate}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label for="updatedEndDate">{label.UpdatedEndDate}</Label>
                                            <DateInput
                                                id="updatedEndDate"
                                                mindate={this.state.updatedStartDate}
                                                value={this.state.updatedEndDate}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    this.setState({
                                                        updatedEndDate: value ? value : null
                                                    })
                                                }}
                                                placeholder={label.EnterEndDate}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <ItemOption
                                                    title={label.SelectProduct}
                                                    objects={products}
                                                    default={!isEmpty(product) ? product.id : null}
                                                    fieldForLabel={"name"}
                                                    callback={(product) => {
                                                        this.setState({
                                                            product: product
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <ItemOption
                                                    title={label.SelectStation}
                                                    fieldForLabel={"name"}
                                                    objects={stations}
                                                    default={station.id}
                                                    callback={(station)=>{
                                                        if(!isEmpty(station)){
                                                            if(station.id!=this.state.station.id){
                                                                charger = {}
                                                            }
                                                            this.setState({
                                                                station:station,
                                                                charger:{},
                                                                chargers:[]
                                                            }, () => {
                                                                this.fetchChargers(station)
                                                            })

                                                        }else{
                                                            this.setState({
                                                                station:{},
                                                                chargers:[]
                                                            })
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <ItemOption
                                                    title={label.SelectCharger}
                                                    fieldForLabel={"name"}
                                                    objects={chargers}
                                                    default={charger.id}
                                                    callback={(charger)=>{
                                                        if(charger){
                                                            this.setState({
                                                                charger:charger
                                                            })
                                                        }else{
                                                            this.setState({
                                                                charger:{}
                                                            })
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label>{label.Searching}</Label>
                                                <SearchInput
                                                    placeholder={label.SearchReferenceSerialModelUser}
                                                    value={this.state.inputSearch}
                                                    onChange={e => {
                                                        this.setState({
                                                            inputSearch: e.target.value
                                                        }, () => {
                                                            if (this.state.inputSearch === '') {
                                                                changeParam3(this.props, 'page', 1, 'tab', this.state.key, 'search', this.state.inputSearch)
                                                            }
                                                        })
                                                    }}
                                                    onEnter={e => {
                                                        if (this.state.inputSearch !== '') {
                                                            changeParam3(this.props, 'page', 1, 'tab', this.state.key, 'search', this.state.inputSearch)
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardHeader>
                                                            {label.TotalRecord}
                                                        </CardHeader>
                                                        <CardBody>
                                                            <h4 style={{color: 'green'}}><strong>{totalElement}</strong>
                                                            </h4>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col>
                                                    <Card>
                                                        <CardHeader>
                                                            {label.TotalPage}
                                                        </CardHeader>
                                                        <CardBody>
                                                            <h4 style={{color: 'blue'}}>{totalPage}</h4>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                            <Row>
                                                <Col md={6}>
                                                    <Button
                                                        block
                                                        className={"float-left"}
                                                        color={"primary"}
                                                        onClick={event => {
                                                            navigatePage(this.props, 1)
                                                        }}>
                                                        <IoMdSettings/>&nbsp;{label.ApplyFilter}
                                                    </Button>
                                                </Col>
                                                <Col md={6}>
                                                    <Button block className={"float-left"} color={"yellow"}
                                                            onClick={event => {
                                                                this.resetFilter()
                                                            }}><MdRestore/>&nbsp;{label.ResetFilter}</Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <Button block className={"float-left"} color={"green"}
                                                            onClick={event => {
                                                                this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, true, true)
                                                            }}><FaFileExcel/>&nbsp;{label.DownloadExcel}</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Collapse>
                        </Card>
                    </Col>
                </Row>
                {this.rendertransaction()}
            </Page>
        )
    }

    fetchBadgeCount(transactionStatusId) {
        let {station, charger} = this.state
        let params = {
            transactionStatusId: transactionStatusId,
            ascending: this.state.ascending,
            sortir: this.state.sortir,
            search: this.state.search,
            page: this.state.page - 1,
            createdStartDate: this.state.createdStartDate,
            createdEndDate: this.state.createdEndDate,
            updatedStartDate: this.state.updatedStartDate,
            updatedEndDate: this.state.updatedEndDate,
            productId: this.state.product.id,
            stationId : station.id,
            chargerId : charger.id,
            excelMode: false,
            badgeCountMode:true
        }
        this.get(Global.API.TRANSACTIONS, {params: params}, null, (response) => {
            if (response && response.code == 200) {
                this.setState({
                    ['size-' + transactionStatusId ? transactionStatusId : "all"]: response.totalElement
                })
            }
        }, false, false);
    }


    badgeTabLabel(label, transactionStatusId, color) {
        let size = this.state['size-' + transactionStatusId ? transactionStatusId : "all"];
        if (!size || size == 0) {
            return (<span>{label}</span>)
        }
        return (
            <>
                <span>{label}</span>
                &nbsp;&nbsp;
                <span
                    style={{
                        paddingTop: '2px',
                        paddingBottom: '2px',
                        paddingLeft: '6px',
                        paddingRight: '6px',
                        background: color ? color : 'red',
                        color: 'white',
                        borderRadius: '20%',
                        minWidth: '24px',
                        display: 'inline-block',
                        textAlign: 'center'
                    }}>
                    {size}
                </span>
            </>)
    }

    rendertransaction = () => {
        return (
            <Row key={2}>
                <Col>
                    <Card>
                        <CardHeader>
                            <strong>{label.transaction}</strong>
                            <br/><br/>
                            <Row>
                                <Col md={3}>
                                    {label.SortBy} :
                                    <UncontrolledButtonDropdown key={1}>
                                        <DropdownToggle
                                            caret
                                            color="white"
                                            className="text-capitalize m-1">
                                            {
                                                sortirMap(this.state.sortir.toString())
                                            }
                                        </DropdownToggle>
                                        <DropdownMenu style={{transform: 'none'}}>
                                            <DropdownItem
                                                onClick={e => (this.refreshTransactions(this.state.ascending, "ja.reference", true))}>{label.Reference}</DropdownItem>
                                            <DropdownItem
                                                onClick={e => (this.refreshTransactions(this.state.ascending, "ja.paymentReference", true))}>{label.PaymentReference}</DropdownItem>
                                            <DropdownItem
                                                onClick={e => (this.refreshTransactions(this.state.ascending, "ja.created", true))}>{label.Created}</DropdownItem>
                                            <DropdownItem
                                                onClick={e => (this.refreshTransactions(this.state.ascending, "ja.updated", true))}>{label.Updated}</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>
                                <Col md={3}>
                                    {label.Sortir} :
                                    <UncontrolledButtonDropdown key={2}>
                                        <DropdownToggle
                                            caret
                                            color="white"
                                            className="text-capitalize m-1">
                                            {
                                                this.state.ascending ? label.Ascending : label.Descending
                                            }
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem
                                                onClick={e => (this.refreshTransactions(true, this.state.sortir, true))}>{label.Ascending}</DropdownItem>
                                            <DropdownItem
                                                onClick={e => (this.refreshTransactions(false, this.state.sortir, true))}>{label.Descending}</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>
                                <Col md={6}>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Tabs
                                activeKey={this.state.key}
                                onSelect={key => {
                                    changeParam2(this.props, 'page', 1, 'tab', key)
                                }
                                }>
                                <Tab eventKey={label.All}
                                     title={this.badgeTabLabel(label.All, undefined, TRANSACTION_STATUS_COLOR['ALL'])}>
                                    {this.rendertransactionTable()}
                                </Tab>
                                {
                                    this.state.transactionStatuses.map((item, index) => (
                                        <Tab key={index} eventKey={item.id}
                                             title={this.badgeTabLabel(item.label, item.id, TRANSACTION_STATUS_COLOR[item.name])}>
                                            {this.rendertransactionTable()}
                                        </Tab>
                                    ))
                                }
                            </Tabs>
                        </CardBody>
                        <SockJsClient
                            url={Global.BASE_URL}
                            topics={[Global.API.LISTEN_UPDATE_TRANSACTIONS]}
                            autoReconnect={true}
                            debug={Global.DEBUG}
                            heartbeat={5000}
                            onMessage={(message) => {
                                this.successToast(message)
                                this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, true)
                            }}
                            onConnectFailure={(object)=>{
                                console.error(object)
                            }}
                            ref={(client) => {
                                this.clientRef = client
                            }}/>
                    </Card>
                </Col>
            </Row>
        )
    }


    rendertransactionTable = () => {
        return (
            <Card>
                <CardBody>
                    <Table hover responsive>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{label.ReferenceCode}</th>
                            <th>{label.PaymentReference}</th>
                            <th style={{width:'20%'}}>{label.PaymentMethod}</th>
                            <th>{label.TransactionAmount}</th>
                            <th>{label.Status}</th>
                            <th>{label.User}</th>
                            <th>{label.Created}</th>
                            <th>{label.Updated}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.transactions.map((item, index) => (
                                <tr key={item.id}>
                                    <th scope="row">{((this.state.page - 1) * this.state.pageElement) + (++index)}</th>
                                    <td><a href={'/transactionDetail?id=' + item.id}>{item.reference}</a></td>
                                    <td><a href={'/transactionDetail?id=' + item.id}>{item.paymentReference}</a></td>
                                    <td>{item.subPaymentMedia.label}</td>
                                    <td>
                                        {currencyFormat(item.totalTransactionAmount, CURRENCY_SYMBOL)}
                                    </td>
                                    <td>{item.transactionStatus.label}</td>
                                    <td>
                                        {
                                            item.user.fullname
                                        }
                                    </td>
                                    <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                    <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </CardBody>
                <Pagination {...this.props} currentPage={parseInt(this.state.page)} pageCount={this.state.totalPage}/>
            </Card>
        )
    }
}

export default Object.assign(TransactionPage, {ENDPOINT : "/transactions"})
