import * as React from 'react';
import BaseComponent from '../BaseComponent';
import {Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Modal, Row} from "reactstrap";
import productImageDefault from 'assets/img/banner/rack.png';
import * as PropTypes from 'prop-types';
import label from "../../message/Label"
import MyCardImage from "../Card/MyCardImage";


/*= (message, okCallback, cancelCallback) =>*/
export default class ProductModal extends BaseComponent{

    constructor(props){
        super(props);
        this.state = {
            product:this.props.product
        }
    }
    componentWillReceiveProps(props, nextContext) {
        if(this.props!==props){
            this.setState({
                product:props.product
            })
        }
    }


    render(){
        let {product} = this.state
        let productCategory = product.productCategory?product.productCategory:{}
        let groupForm = product.groupForm?product.groupForm:{}
        return (
            <>
                {super.render()}
                <Modal
                    autoFocus={false}
                    style={{minWidth:'80%'}}
                    size="xl"
                    centered={true}
                    isOpen={this.props.show}
                    backdrop={true}>
                    <Card>
                        <CardHeader>
                            <h3 style={{display:'contents'}}>{label.ModelDetail}</h3>
                            <div
                                style={{float:"right", textAlign:'right'}}>
                                <Button onClick={() => {
                                    this.props.onClickOk()
                                }}>{label.Close}</Button>
                            </div>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={4} style={{textAlign:'left'}}>
                                    <Card>
                                        <CardBody>
                                            <MyCardImage src={productImageDefault}></MyCardImage>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={8}>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for="systemType">{label.SystemType}</Label>
                                                <Input
                                                    type="text"
                                                    name="systemType"
                                                    readOnly={true}
                                                    value={product.systemType}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="periodType">{label.Period}</Label>
                                                <Input
                                                    type="text"
                                                    name="periodType"
                                                    readOnly={true}
                                                    value={product.periodTypeLabel}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for="description">{label.Description}</Label>
                                                <Input
                                                    type="text"
                                                    name="description"
                                                    readOnly={true}
                                                    value={product.description}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for="description">{label.InspectionLimit}</Label>
                                                <Input
                                                    type="text"
                                                    name="transactionLimitTypeLabel"
                                                    readOnly={true}
                                                    value={product.inspectionLimitTypeLabel}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="description">{label.TotalInspection}</Label>
                                                <Input
                                                    type="text"
                                                    name="inspectionCount"
                                                    readOnly={true}
                                                    value={product.inspectionCount}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="sizeOfRectifierControllerSerialNumber">{label.SizeOfRectifierControllerSerialNumber}</Label>
                                                <Input
                                                    type="text"
                                                    name="sizeOfRectifierControllerSerialNumber"
                                                    readOnly={true}
                                                    value={product.sizeOfRectifierControllerSerialNumber}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for="category">{label.Category}</Label>
                                                <Input
                                                    type="text"
                                                    name="category"
                                                    readOnly={true}
                                                    value={productCategory.name}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="inspectionLimitPerUserLabel">{label.MaximumInspectionCanBeApplied}</Label>
                                                <Input
                                                    type="text"
                                                    name="inspectionLimitPerUserLabel"
                                                    readOnly={true}
                                                    value={product.inspectionLimitPerUserLabel}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for="documentNumber">{label.DocumentNumber}</Label>
                                                <Input
                                                    type="text"
                                                    name="documentNumber"
                                                    readOnly={true}
                                                    value={groupForm.documentNumber}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="groupForm">{label.GroupForm}</Label>
                                                <Input
                                                    type="text"
                                                    name="groupForm"
                                                    readOnly={true}
                                                    value={groupForm.name}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for="message"><strong>{label.Scope}</strong></Label>
                                                <Card>
                                                    <CardBody>
                                                        <div style={{paddingLeft:'16px', paddingRight:'16px'}} dangerouslySetInnerHTML={{__html: product.scope}} />
                                                    </CardBody>
                                                </Card>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for="message"><strong>{label.ReferenceDocument}</strong></Label>
                                                <Card>
                                                    <CardBody>
                                                        <div style={{paddingLeft:'16px', paddingRight:'16px'}} dangerouslySetInnerHTML={{__html: product.referenceDocument}} />
                                                    </CardBody>
                                                </Card>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label for="message"><strong>{label.TestingEquipmentAndAccessories}</strong></Label>
                                                <Card>
                                                    <CardBody>
                                                        <div style={{paddingLeft:'16px', paddingRight:'16px'}} dangerouslySetInnerHTML={{__html: product.testingEquipmentAndAccessories}} />
                                                    </CardBody>
                                                </Card>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Modal>
            </>
           )
    }
}

ProductModal.propTypes = {
    show:PropTypes.bool.isRequired,
    product:PropTypes.object.isRequired,
    onClickOk:PropTypes.func.isRequired,
}
