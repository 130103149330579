import React from 'react';
import {Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Card, CardBody} from 'reactstrap';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Input from 'reactstrap/es/Input';
import ItemOption from '../Widget/ItemOption';
import Global from '../../utils/Global';
import BaseComponent from '../BaseComponent';
import label from '../../message/Label'
import {MdRefresh} from "react-icons/all";
import {isEmpty, isResponseOk} from "../../utils/Utilities";
import QRCode from "react-qr-code";
/*= (message, okCallback, cancelCallback) =>*/
export default class ChargerGunModal extends BaseComponent{

    constructor(props){
        super(props);
        let chargerGun = this.props.chargerGun?this.props.chargerGun:{}
        let charger = this.props.charger?this.props.charger:{}
        let station = charger.station?charger.station:{}
        let state = this.state
        state.chargerGunType = chargerGun.chargerGunType?chargerGun.chargerGunType:{}
        state.chargerGun = chargerGun
        state.stations = []
        state.chargers = []
        state.station = station
        state.modal= this.props.modal
        state.backdrop= false
        state.charger = charger
        state.chargerGunTypes = []
        state.okCallback = this.props.okCallback
        state.closeCallback = this.props.closeCallback
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        let modal = this.state.modal
        if(modal!=props.modal){
            let chargerGun = props.chargerGun?props.chargerGun:{}
            let charger = props.charger?props.charger:{}
            let station = charger.station?charger.station:{}
            let chargerGunType = chargerGun.chargerGunType?chargerGun.chargerGunType:{}

            this.setState({
                modal:props.modal,
                charger: charger,
                station:station,
                okCallback : props.okCallback,
                closeCallback : props.closeCallback,
                chargerGun:chargerGun,
                chargerGunType:chargerGunType,
            })
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchChargerGunTypes()
        this.fetchStations()
        if(this.state.station){
            this.fetchChargers(this.state.station)
        }
        // this.generateCode(false)


    }

    fetchStations = () =>{
        this.get(Global.API.STATIONS, {
            params:{
                ascending:true,
                sortir:"jc.name"
            }
        }, null, res=>{
            if(res.code === 200){
                this.setState({
                    stations : res.data,
                })
            }
        },false, true);

    }
    fetchChargerGunTypes = () =>{
        this.get(Global.API.CHARGER_GUN_TYPES, null, null, response=>{
            if(response.code===200){
                let chargerGunTypes = response.data
                this.setState({
                    chargerGunTypes : chargerGunTypes
                })
            }
        }, true, true)
    }

    fetchChargers = (station) =>{
        this.get(Global.API.CHARGERS, {
            params:{
                ascending:true,
                sortir:"jt.created",
                stationId:station.id
            }
        }, null, response=>{
            if(response.code===200){
                let chargers = response.data
                this.setState({
                    chargers : chargers
                })
            }
        }, true, true)
    }

    async generateCode(force){
        let {chargerGun, charger} = this.state
        if(!force && chargerGun.code){
            return chargerGun.code
        }
        console.log("charger =",charger.id)
        let params = {
            chargerCode: charger.code ?? "",
        };
        console.log("-> params = ",params)
        let response = await this.asyncGet(Global.API.CHARGER_GUN_GENERATE_CODE, {params}, null, true, true);
        let code = undefined;
        if(isResponseOk(response)){
            code = response.data
        } else {
            alert("Harap pilih Stasiun & Charger Terlebih dahulu")
        }
        chargerGun.code = code
        this.setState({chargerGun})
        return code;
    }


    saveChargerGun=()=>{
        let {charger, chargerGunType, chargerGun} = this.state
        if(isEmpty(charger)){
            return
        }
        if(isEmpty(chargerGun.code)){
            return
        }
        if(isEmpty(chargerGun.label)){
            return
        }
        if(isEmpty(chargerGunType)){
            return
        }
        chargerGun.charger = charger
        chargerGun.chargerGunType = chargerGunType
        this.post(Global.API.CHARGER_GUN_SAVE, chargerGun, null, response=>{
            if(response.code===200){
                let chargerGun = response.data
                this.successToast(response.message)
                this.setState({
                    modal:false
                }, () => {
                    if(this.state.okCallback){
                        this.state.okCallback(chargerGun)
                    }
                })
            }
        }, true, true)
    }

    render(){
        let {stations, station, chargers, charger, chargerGun, chargerGunType, chargerGunTypes} = this.state

        return (
            <Modal size="md" centered={true}
                isOpen={this.state.modal}
                backdrop={false}>
                {super.render()}
                <ModalHeader>
                    {label.ChargerGun}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody style={{textAlign:'center'}}>
                                    <QRCode value={chargerGun.code?chargerGun.code:""}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={10}>
                            <FormGroup>
                                <Label for="code">{label.Code}</Label>
                                <Input
                                    id="code"
                                    type="text"
                                    name="code"
                                    disabled={true}
                                    value={chargerGun.code}
                                    onChange={(e) =>{
                                        e.preventDefault()
                                        chargerGun.code = e.target.value
                                        this.setState({
                                            chargerGun:chargerGun
                                        })

                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label>&nbsp;&nbsp;</Label>
                                <br/>
                                <Button onClick={() => this.generateCode(true)} disabled={!(charger.id)}><MdRefresh/></Button>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="label">{label.Label}</Label>
                                <Input
                                    id="label"
                                    type="text"
                                    name="label"
                                    value={chargerGun.label}
                                    onChange={(e) =>{
                                        e.preventDefault()
                                        chargerGun.label = e.target.value
                                        this.setState({
                                            chargerGun:chargerGun
                                        })

                                    }}
                                    placeholder={label.EnterPosition}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="position">{label.Position}</Label>
                                <Input
                                    id="position"
                                    type="text"
                                    name="position"
                                    value={chargerGun.position}
                                    onChange={(e) =>{
                                        e.preventDefault()
                                        chargerGun.position = e.target.value
                                        chargerGun.position = chargerGun.position.replace(/[^\d+]/g,'')
                                        this.setState({
                                            chargerGun:chargerGun
                                        })

                                    }}
                                    placeholder={label.EnterPosition}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <ItemOption
                                    title={label.ChargerGunType}
                                    fieldForLabel={"type"}
                                    objects={chargerGunTypes}
                                    default={chargerGunType.id}
                                    callback={(chargerGunType)=>{
                                        if(chargerGunType){
                                            chargerGun.chargerGunType = chargerGunType
                                            this.setState({
                                                chargerGunType:chargerGunType
                                            })
                                        }else{
                                            chargerGun.chargerGunType = undefined
                                            this.setState({
                                                chargerGunType:{}
                                            })
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {
                        this.props.chargerOption&&(
                            <>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <ItemOption
                                                title={label.Stations}
                                                fieldForLabel={"name"}
                                                objects={stations}
                                                default={station.id}
                                                callback={(station)=>{
                                                    if(!isEmpty(station)){
                                                        if(station.id!=this.state.station.id){
                                                            charger = {}
                                                        }
                                                        this.setState({
                                                            station:station,
                                                            charger:{},
                                                            chargers:[]
                                                        }, () => {
                                                            this.fetchChargers(station)
                                                        })

                                                    }else{
                                                        this.setState({
                                                            station:{},
                                                            chargers:[]
                                                        })
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <ItemOption
                                                title={label.Chargers}
                                                fieldForLabel={"name"}
                                                objects={chargers}
                                                default={charger.id}
                                                callback={(charger)=>{
                                                    if(charger){
                                                        this.setState({
                                                            charger:charger
                                                        })
                                                    }else{
                                                        this.setState({
                                                            charger:{}
                                                        })
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                </ModalBody>
                <ModalFooter>
                    <Row>
                        <Col>
                            <Button color="primary" onClick={e=>{
                                this.saveChargerGun()
                            }}>
                                {label.Save}
                            </Button>
                        </Col>
                        <Col>
                            <Button color="secondary" onClick={e=>{
                                e.preventDefault()
                                this.setState({
                                    modal:false
                                })
                                if(this.state.closeCallback){
                                    this.state.closeCallback()
                                }
                            }}>
                                {label.Cancel}
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        )
    }
}

ChargerGunModal.propTypes = {
    modal:PropTypes.bool,
    chargerOption:PropTypes.bool.isRequired,
    charger:PropTypes.object.isRequired,
    chargerGun:PropTypes.object,
    okCallback:PropTypes.func,
    closeCallback:PropTypes.func,
}
