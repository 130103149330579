import React from 'react';
import CurrencyInput from 'react-currency-input';
import PropTypes from 'prop-types';

export default class NumberInput extends React.Component{

    constructor(props) {
        super(props);

    }

    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            this.setState({})
        }

    }


    render() {
        let props = this.props
        return (
            <CurrencyInput {...props}
                name={props.name}
                className={props.className}
                value={props.value}
                ref={props.name}
                onChangeEvent={(e, maskedvalue, floatvalue)=>{
                    if(props.onChangeEvent){
                        props.onChangeEvent(e, maskedvalue, floatvalue)
                    }
                }}
                allowNegative={false}
                thousandSeparator=""
                precision={0}
                placeholder={props.placeholder}>
            </CurrencyInput>
        );
    }
}

NumberInput.propTypes = {
    name:PropTypes.string,
    className:PropTypes.string,
    value:PropTypes.any,
    placeholder:PropTypes.string,
    onChangeEvent:PropTypes.func
}
