import Page from 'components/Page';
import React from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Label,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import BasePage from './BasePage';
import Global, {DD_MM_YYYY_HH_MM_SS} from '../utils/Global';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import SearchInput from '../components/SearchInput';
import {deleteParam, navigatePage, parseDate, sortirMap} from '../utils/Utilities';
import Pagination from '../components/Pagination';
import queryString from 'query-string';
import {MdDelete, MdRestore} from 'react-icons/md';
import {IoMdOpen, IoMdSettings} from 'react-icons/io';
import label from '../message/Label';
import {FiArrowDownCircle, FiArrowUpCircle} from 'react-icons/fi';
import DateInput from '../components/Widget/DateInput';
import {FaFileExcel} from 'react-icons/fa';
import {array} from "prop-types";


const tableTypes = ['', 'bordered', 'striped', 'hover'];

class UserDevicePage extends BasePage{


    constructor(props) {
        super(props);
        this.state ={
            user:{},
            userDevices : [],
            userDevice:{},
            createdStartDate:null,
            createdEndDate:null,
            lastOpenStart:null,
            lastOpenEnd:null,
            modalDeleteConfirm:false,
            ascending:false,
            sortir:'ud.created',
            search:"",
            page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
            totalPage:0,
            totalElement:0,
            pageElement:0,

        }
    }

    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            this.setState({
                page:queryString.parse(props.query).page?queryString.parse(props.query).page:1
            }, () => {
                this.fetchAll(this.state.ascending, this.state.sortir, this.state.search,true, false)
            })
        }
    }


    componentDidMount() {
        super.componentDidMount();
        this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, true, false)
    }

    fetchAll = (ascending, sortir, search, progressing, excelMode) => {
        let platformArray = [Global.ANDROID, Global.IOS];
        platformArray.toString()
        let params = {
            createdStart:this.state.createdStartDate,
            createdEnd:this.state.createdEndDate,
            lastOpenStart:this.state.lastOpenStart,
            lastOpenEnd:this.state.lastOpenEnd,
            page:this.state.page-1,
            ascending:ascending,
            sortir:sortir,
            search:search,
            platforms:platformArray.toString(),
            excelMode:excelMode?excelMode:false
        }
        if(excelMode){
            this.asyncDownloadGet(Global.API.USER_DEVICES, {params:params}, null,true);
            return
        }else{
            this.get(Global.API.USER_DEVICES, {
                params:params
            }, null, res=>{
                if(res.code === 200){
                    this.setState({
                        userDevices : res.data,
                        totalPage: res.totalPage,
                        totalElement: res.totalElement,
                        pageElement: res.pageElement,
                    })
                }
            }, progressing, true);
        }
    }

    confirmDelete = () =>{
        this.setState({
            modalDeleteConfirm:true
        })
    }

    delete = () => {
        this.setState({
            modalDeleteConfirm:false
        }, () => {
            this.get(Global.API.USER_DEVICE_DELETE+"/"+this.state.userDevice.id, null, null, res => {
                if(res.code===200){
                    this.successToast(res.message)
                    this.setState({
                        category:null
                    }, () => {
                        this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, true, false)
                    })
                }
            }, true, true);
        })
    }

    closeDialog = () =>{
        this.setState({
            modalAddConfirm:false,
            modalDeleteConfirm:false
        })
    }

    refreshUserDevice = (ascending, sortir, progressing) => (
        this.setState({
            ascending:ascending,
            sortir:sortir
        }, () => {
            this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, progressing, false)
        })
    )

    resetFilter(){
        this.setState({
            search:"",
            page :1,
            totalPage:0,
            totalElement:0,
            pageElement:0,
            createdStartDate:null,
            createdEndDate:null,
            lastOpenStart:null,
            lastOpenEnd:null,
        }, () => {
            navigatePage(this.props, 1)
        })
    }



    render() {
        let user = this.state.user
        let totalPage =  this.state.totalPage
        let totalElement =  this.state.totalElement
        let pageElement =  this.state.pageElement

        var i = 0 ;

        return (
            <Page
                title={label.UserDevices}
                breadcrumbs={[{ name: label.UserDevices, active: true }]}
                className="TablePage"
            >
                {super.render()}
                <ConfirmDialog
                    showing={this.state.modalDeleteConfirm}
                    title={label.DeleteConfirmationTitle}
                    message={label.BanThisDevice_TheUserWillBeBannedAfterThisPerform}
                    okCallback={this.delete}
                    cancelCallback={this.closeDialog}/>
                <Row key={1}>
                    <Col>
                        <Card>
                            <CardHeader style={{cursor:'pointer'}} onClick={event=>{
                                this.setState({
                                    userFilterExpand:!this.state.userFilterExpand
                                })
                            }}>
                                {
                                    (this.state.userFilterExpand)?(<FiArrowUpCircle/>):((<FiArrowDownCircle/>))
                                }
                                &nbsp;{label.AdvanceFilter} ({label.ClickToExpandFilterContent})
                            </CardHeader>
                            <Collapse isOpen={this.state.userFilterExpand}>
                                <CardHeader>
                                    <Row>
                                        <Col md={3}>
                                            <Label for="createdStartDate">{label.CreatedStartDate}</Label>
                                            <DateInput
                                                id="createdStartDate"
                                                value={this.state.createdStartDate}
                                                maxdate={this.state.createdEndDate}
                                                onChange={(e) =>{
                                                    let value = e.target.value
                                                    this.setState({
                                                        createdStartDate : value?value:null
                                                    })
                                                }}
                                                placeholder={label.EnterStartDate}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label for="createdEndDate">{label.CreatedEndDate}</Label>
                                            <DateInput
                                                id="createdEndDate"
                                                mindate={this.state.createdStartDate}
                                                value={this.state.createdEndDate}
                                                onChange={(e) =>{
                                                    let value = e.target.value
                                                    this.setState({
                                                        createdEndDate :  value?value:null
                                                    })
                                                }}
                                                placeholder={label.EnterEndDate}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label for="lastOpenStart">{label.LastOpenStart}</Label>
                                            <DateInput
                                                id="lastOpenStart"
                                                value={this.state.lastOpenStart}
                                                maxdate={this.state.lastOpenEnd}
                                                onChange={(e) =>{
                                                    let value = e.target.value
                                                    this.setState({
                                                        lastOpenStart : value?value:null
                                                    })
                                                }}
                                                placeholder={label.EnterStartDate}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label for="lastOpenEnd">{label.LastOpenEnd}</Label>
                                            <DateInput
                                                id="lastOpenEnd"
                                                mindate={this.state.lastOpenStart}
                                                value={this.state.lastOpenEnd}
                                                onChange={(e) =>{
                                                    let value = e.target.value
                                                    this.setState({
                                                        lastOpenEnd :  value?value:null
                                                    })
                                                }}
                                                placeholder={label.EnterEndDate}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label for="search">{label.Search}</Label>
                                                        <SearchInput
                                                            placeholder={label.EnterDeviceIdUserCodeOrNameToSearch}
                                                            value={this.state.search}
                                                            onChange={e=>{
                                                                this.setState({
                                                                    search:e.target.value
                                                                }, () => {
                                                                    if(this.state.search===''){
                                                                        deleteParam(this.props, 'page')
                                                                    }
                                                                })
                                                            }}
                                                            onEnter={e=>{
                                                                if(this.state.search!==''){
                                                                    deleteParam(this.props, 'page')
                                                                }
                                                            }}
                                                        />

                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardHeader>
                                                            {label.TotalRecord}
                                                        </CardHeader>
                                                        <CardBody>
                                                            <h4 style={{color:'green'}}><strong>{totalElement}</strong></h4>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col>
                                                    <Card>
                                                        <CardHeader>
                                                            {label.TotalPage}
                                                        </CardHeader>
                                                        <CardBody>
                                                            <h4 style={{color:'blue'}}>{totalPage}</h4>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                            <Row>
                                                <Label>&nbsp;</Label>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <Button block className={"float-left"} color={"primary"}
                                                            onClick={event => {
                                                                navigatePage(this.props, 1)
                                                            }}><IoMdSettings/>&nbsp;{label.ApplyFilter}</Button>
                                                </Col>
                                                <Col md={6}>
                                                    <Button block className={"float-left"} color={"yellow"}
                                                            onClick={event => {
                                                                this.resetFilter()
                                                            }}><MdRestore/>&nbsp;{label.ResetFilter}</Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <Button block className={"float-left"} color={"green"}
                                                            onClick={event => {
                                                                this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, true, true)
                                                            }}><FaFileExcel/>&nbsp;{label.DownloadExcel}</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Collapse>
                        </Card>
                    </Col>
                </Row>
                <Row key={2}>
                    <Col>
                        <Card className="mb-6">
                            <CardHeader>
                                <strong>{label.Devices}</strong>
                                <br/><br/>
                                <Row>
                                    <Col md={4}>
                                        {label.SortBy} :
                                        <UncontrolledButtonDropdown key={1}>
                                            <DropdownToggle
                                                caret
                                                color="white"
                                                className="text-capitalize m-1">
                                                {
                                                    sortirMap(this.state.sortir)
                                                }
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={e=>(this.refreshUserDevice(this.state.ascending, "ud.created", true))}>{label.Created}</DropdownItem>
                                                <DropdownItem onClick={e=>(this.refreshUserDevice(this.state.ascending, "ud.ipAddress", true))}>{label.IPAddress}</DropdownItem>
                                                <DropdownItem onClick={e=>(this.refreshUserDevice(this.state.ascending, "ud.userAgent", true))}>{label.UserAgent}</DropdownItem>
                                                <DropdownItem onClick={e=>(this.refreshUserDevice(this.state.ascending, "u.firstname", true))}>{label.Name}</DropdownItem>
                                                <DropdownItem onClick={e=>(this.refreshUserDevice(this.state.ascending, "u.userCode", true))}>{label.UserCode}</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                    <Col md={3}>
                                        {label.Sortir} :
                                        <UncontrolledButtonDropdown key={2}>
                                            <DropdownToggle
                                                caret
                                                color="white"
                                                className="text-capitalize m-1">
                                                {
                                                    this.state.ascending?label.Ascending:label.Descending
                                                }
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={e=>(this.refreshUserDevice(true, this.state.sortir, true))}>{label.Ascending}</DropdownItem>
                                                <DropdownItem onClick={e=>(this.refreshUserDevice(false, this.state.sortir, true))}>{label.Descending}</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                    <Col md={5}>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Table responsive hover>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{label.Platform}</th>
                                            <th>{label.IPAddress}</th>
                                            <th style={{width:'25%'}}>{label.UserAgent}</th>
                                            <th>{label.DeviceId}</th>
                                            <th>{label.Name} | {label.UserCode}</th>
                                            <th>{label.Created}</th>
                                            <th>{label.LastOpen}</th>
                                            <th>{label.Delete}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.userDevices.map((item, index)=>(
                                                <tr key={item.id}>
                                                    <th scope="row">{((this.state.page-1)* this.state.pageElement)+(++index)}</th>
                                                    <td>{item.platform}</td>
                                                    <td>{item.ipAddress}</td>
                                                    <td>{item.userAgent}</td>
                                                    <td>{item.deviceId}</td>
                                                    <td>
                                                        {
                                                            item.user?(<>
                                                                    {item.user.fullname} | {item.user.userCode}
                                                                    &nbsp;&nbsp;
                                                                    <IoMdOpen
                                                                        color="blue" style={{cursor:'pointer'}}
                                                                        onClick={e=>{
                                                                            this.props.history.push('/userDetail?id='+item.user.id)
                                                                        }
                                                                        }/>
                                                                </>
                                                            ):(<strong style={{color:"red"}}>{label.UnrecognizedDueNotAuthenticated}</strong>)
                                                        }
                                                    </td>
                                                    <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                                    <td>{parseDate(item.lastOpenDate, DD_MM_YYYY_HH_MM_SS)}</td>
                                                    <td>
                                                        {item.user && (
                                                            <Button size="sm" color="danger" onClick={e => {
                                                                e.preventDefault()
                                                                this.setState({
                                                                    userDevice: item
                                                                }, () => {
                                                                    this.confirmDelete()
                                                                })
                                                            }}>
                                                                <MdDelete/>
                                                            </Button>
                                                        )
                                                        }
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </Table>
                                </Row>
                            </CardBody>
                            <Pagination {...this.props} currentPage={parseInt(this.state.page)} pageCount={this.state.totalPage} />

                        </Card>
                    </Col>
                </Row>
            </Page>
        );
    }
}

export default Object.assign(UserDevicePage, {ENDPOINT : "/userDevices"})
