import React from 'react';
import {Col, Row} from 'reactstrap';
import queryString from "query-string";
import BaseComponent from "../components/BaseComponent";
import QRCode from "react-qr-code";

class QRCodePage extends BaseComponent {

    constructor(props) {
        super(props);
        let state = this.state
        state.code = queryString.parse(this.props.query).code
        this.setState(state)
    }

    render() {

        let {code} = this.state
        return (
            <div>
            <Row
                style={{
                    alignItems: 'center',
                }}>
                <Col>
                    <div style={{ height: "auto", margin: "0 auto", marginTop:15, width: 256}}>
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={code}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                </Col>
            </Row>
                <Row>
                    <Col>
                        <div style={{ height: "auto", margin: "0 auto", marginTop:15, width: 256, textAlign:'center', fontSize:'1.8em' }}>
                            {code}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Object.assign(QRCodePage, {ENDPOINT: "/qrCode"})
