import React from 'react';
import {Button, Col, FormGroup, Modal, ModalBody, ModalFooter, Row, Table} from 'reactstrap';
import BaseComponent from '../BaseComponent';
import Global from '../../utils/Global';
import Checkbox from '../../components/Checkbox';
import {ModelDto} from '../../model/model';
import PropTypes from 'prop-types';
import ModalPagination from '../ModalPagination';
import SearchInput from '../../components/SearchInput';
import label from '../../message/Label'

/*= (message, okCallback, cancelCallback) =>*/
export default class URLSelectionModal extends BaseComponent{

    products = new Array(ModelDto)
    model = new ModelDto();

    constructor(props){
        super(props);
        let state = this.state
        state.backdrop = false
        state.showing = this.props.showing
        state.products = this.products
        state.model = this.model
        state.selectedUrl = this.props.selectedUrl?this.props.selectedUrl:""
        state.checkedAll = false
        state.allStartDate = null
        state.allEnddDate = null
        state.page = 1
        state.sortir = "jt.updated"
        state.ascending = true
        this.setState(state)
        this.buttonClick = this.buttonClick.bind(this)
    }

    componentWillReceiveProps(props, nextContext) {
        let showing = this.state.showing
        if(this.props!=props){
            this.setState({
                showing:props.showing,
                selectedUrl : props.selectedUrl?props.selectedUrl:""
            }, () => {
                if(props.showing && props.showing!==showing) {
                    this.fetchModels(this.state.ascending, this.state.sortir, this.state.search, this.state.page, true)
                }
            })
        }
    }

    fetchModels = (ascending, sortir, search, page, progressing) => {
        this.get(Global.API.PRODUCTS, {
            params:{
                ascending:ascending,
                sortir:sortir,
                search:search,
                page: page-1
            }
        }, null, res=>{
            if(res.code === 200){
                this.setState({
                    products : res.data,
                    totalPage: res.totalPage,
                    totalElement: res.totalElement,
                    pageElement: res.pageElement,
                })
            }
        }, progressing, true);
    }

    buttonClick (e, button){
        e.preventDefault()
        if(button===label.Ok){
            if(this.props.okCallback!=null){
                this.props.okCallback()
            }
        }else{
            if(this.props.cancelCallback!=null){
                this.props.cancelCallback()
            }
        }
    }

    close = () =>{
        this.setState({
            model:{},
            products:[],
            showing: false,
        })
    }

    render(){
        let selectedUrl = this.state.selectedUrl ;
        let products = this.state.products ;
        return (
            /*xl-lg-sm*/
            <Modal
                size="xl"
                centered={true}
                isOpen={this.state.showing}
                backdrop={true}
                zIndex="1">
                {super.render()}
                <ModalBody>
                    <Row>
                        <Col>
                            <h3>{label.ModelList}</h3>
                        </Col>
                        <Col>
                            <FormGroup>
                                <SearchInput
                                    placeholder={label.EnterTitleSubtitleSlugBrandOrCategoryToSearch}
                                    value={this.state.search}
                                    onChange={e=>{
                                        this.setState({
                                            search:e.target.value
                                        }, () => {
                                            if(this.state.search===''){
                                                this.setState({
                                                    page:1
                                                }, () => {
                                                    this.fetchModels(this.state.ascending, this.state.sortir, this.state.search, this.state.page, true)
                                                })
                                            }
                                        })
                                    }}
                                    onEnter={e=>{
                                        if(this.state.search!==''){
                                            this.setState({
                                                page:1
                                            }, () => {
                                                this.fetchModels(this.state.ascending, this.state.sortir, this.state.search, this.state.page, true)
                                            })
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalBody>
                    <Row>
                        <Table responsive hover>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{label.Title}</th>
                                <th>Subtitle</th>
                                <th>Slug</th>
                                <th>URL</th>
                                <th>Check</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                products.map((item, index)=>(
                                    <tr valign="center" key={index}>
                                        <th scope="row">{++index}</th>
                                        <td><a style={{textDecoration:'underline', color:'blue'}} href={"/modelDetail?id="+item.id}>{item.title}</a></td>
                                        <td>{item.subtitle}</td>
                                        <td>{item.slug}</td>
                                        <td>{item.completeUrl}</td>
                                        <td>
                                            <Checkbox
                                                checked={selectedUrl===item.completeUrl}
                                                onChange={
                                                    checked => {
                                                        if(checked && checked===true){
                                                            selectedUrl = item.completeUrl
                                                        }else{
                                                            selectedUrl = ""
                                                        }
                                                        this.setState({
                                                            selectedUrl:selectedUrl
                                                        })
                                                    }
                                                }/>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </Table>
                    </Row>
                </ModalBody>
                <ModalPagination {...this.props} currentPage={this.state.page} pageCount={this.state.totalPage} onSelect={(page)=>{
                    this.setState({
                        page:page
                    }, () => {
                        this.fetchModels(this.state.ascending, this.state.sortir, this.state.search, this.state.page, true)
                    })
                }}/>
                <ModalFooter>
                    <Button color="secondary" onClick={e=>{
                        e.preventDefault()
                        if(this.props.cancelCallback){
                            this.props.cancelCallback()
                        }
                        this.close()
                    }}>
                        {label.Cancel}
                    </Button>
                    <Button color="primary" onClick={e=>{
                        e.preventDefault()
                        if(this.props.okCallback){
                            this.props.okCallback(selectedUrl)
                        }
                        this.close()
                    }}>
                        Select
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

URLSelectionModal.propTypes = {
    selectedUrl : PropTypes.string,
    showing : PropTypes.bool.required,
    okCallback : PropTypes.func,
    cancelCallback : PropTypes.func
}
