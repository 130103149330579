import Page from 'components/Page';
import React from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import BasePage from './BasePage';
import Global, {DD_MM_YYYY_HH_MM_SS} from '../utils/Global';
import {allIsEmpty, cloneObject, isEmpty, parseDate, sortirMap} from '../utils/Utilities';
import {MdEdit} from 'react-icons/md';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import ActiveOption from '../components/Widget/ActiveOption';
import ActiveLabel from '../components/Widget/ActiveLabel';
import label from '../message/Label'

const tableTypes = ['', 'bordered', 'striped', 'hover'];


class ChargerTypePage extends BasePage{
  constructor(props) {
    super(props);
    this.state = {
      chargerTypes : [],
      chargerType:{},
      modalAddConfirm:false,
      modalDeleteConfirm:false,
      modal: false,
      ascending:true,
      sortir:'created',
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchAll()
  }

  confirmSave = () =>{
    var chargerType = this.state.chargerType
    if(chargerType.id){
      if(allIsEmpty(chargerType.type)){
        this.showDialog(label.Warning, label.EnterChargerType)
      }else{
        this.setState({
          modalAddConfirm:true
        })
      }
    }else{
      if(allIsEmpty(chargerType.type)){
        this.showDialog(label.Warning, label.EnterChargerType)
      }else{
        this.setState({
          modalAddConfirm:true
        })
      }
    }
  }


  save = () => {
    let chargerType = this.state.chargerType
    var formData = new FormData();

    if(!chargerType.id && !chargerType.active){
      chargerType.active = false ;
    }

    formData.append("chargerTypeGson", JSON.stringify(chargerType))
    this.setState({
      modalAddConfirm:false
    }, () => {
      this.post(Global.API.CHARGER_TYPE_SAVE, formData, null, res=>{
        if(res.code===200){
          this.setState({
            chargerType:res.data,
          }, () => {
            this.showDialog(label.Success, res.message)
            this.fetchAll()
          })
        }
      },  true, true)
    })
  }


  confirmDelete = () =>{
    this.setState({
      modalDeleteConfirm:true
    })
  }

  delete = () => {
    this.setState({
      modalDeleteConfirm:false
    }, () => {
      this.get(Global.API.CHARGER_TYPE_DELETE+"/"+this.state.chargerType.id, null, null, res => {
        if(res.code===200){
          this.setState({
            chargerType:undefined
          }, () => {
            this.fetchAll()
          })
        }
      },true, true);
    })
  }

  closeDialog = () =>{
    this.setState({
      modalAddConfirm:false,
      modalDeleteConfirm:false
    })
  }

  fetchAll = () => {
    this.get(Global.API.CHARGER_TYPES, {
      params:{
        ascending:this.state.ascending,
        sortir:this.state.sortir,
      }
    }, null, response=>{
      if(response.code === 200){
        this.setState({
          chargerTypes : response.data
        })
      }
    }, true, true);
  }

  refresh = (ascending, sortir) => (
      this.setState({
        ascending:ascending,
        sortir:sortir
      }, () => {
        this.fetchAll()
      })
  )


  render() {
    let aspect = 4/4
    return (
        <Page
            title={label.ChargerType}
            breadcrumbs={[{ name: label.ChargerType, active: true }]}
            className="TablePage">
          {super.render()}
          <ConfirmDialog
              showing={this.state.modalAddConfirm}
              title={label.UpdateConfirmation}
              message={label.UpdateConfirmation}
              okCallback={this.save}
              cancelCallback={this.closeDialog}/>
          <ConfirmDialog
              showing={this.state.modalDeleteConfirm}
              title={label.DeleteConfirmationTitle}
              message={label.DeleteConfirmationMessage}
              okCallback={this.delete}
              cancelCallback={this.closeDialog}/>
          <Row key={1}>
            <Col>
              <Card>
                <CardBody >
                  <Row>
                    <Col md={3}>
                      <Card>
                        <CardHeader>{label.Form}</CardHeader>
                        <CardBody>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label for="type">{label.Type}</Label>
                                <Input
                                    type="text"
                                    name="type"
                                    value={!isEmpty(this.state.chargerType.type)?this.state.chargerType.type:""}
                                    onChange={(e) =>{
                                      let ctg = this.state.chargerType
                                      if(ctg!=null){
                                        ctg.type = e.target.value
                                        this.setState({
                                          chargerType:ctg
                                        })
                                      }
                                    }}
                                    placeholder={label.EnterChargerType}
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label for="description">{label.Description}</Label>
                                <Input
                                    type="textarea"
                                    lines={5}
                                    name="description"
                                    value={(this.state.chargerType)?this.state.chargerType.description:""}
                                    onChange={(e) =>{
                                      let ctg = this.state.chargerType
                                      if(ctg!=null){
                                        ctg.description = e.target.value
                                        this.setState({
                                          chargerType:ctg
                                        })
                                      }
                                    }}
                                />
                              </FormGroup>
                              <FormGroup>
                                <ActiveOption
                                    default={this.state.chargerType.active}
                                    callback={(active)=>{
                                      let ctg = this.state.chargerType
                                      if(ctg!=null){
                                        ctg.active = active
                                        this.setState({
                                          chargerType:ctg
                                        })
                                      }

                                    }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonGroup className="float-left">
                                <Button color="danger" onClick={e => (
                                    this.setState({
                                      chargerType:{},
                                    })
                                )}>{label.Cancel}</Button>
                              </ButtonGroup>
                            </Col>
                            <Col>
                              <ButtonGroup className="float-right">
                                <Button color="primary" onClick={e=>(
                                    this.confirmSave()
                                )}> {
                                  (this.state.chargerType!=null && this.state.chargerType.id!=null)?label.Update:label.Add
                                } </Button>

                              </ButtonGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={9}>
                      <Card body>
                        <Row>
                          <Col>
                            {label.SortBy} :
                            <UncontrolledButtonDropdown key={1}>
                              <DropdownToggle
                                  caret
                                  color="white"
                                  className="text-capitalize m-1">
                                {
                                  sortirMap(this.state.sortir)
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "type"))}>{label.Type}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "created"))}>{label.Created}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "updated"))}>{label.Updated}</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Col>
                          <Col>
                            {label.Sortir} :
                            <UncontrolledButtonDropdown key={2}>
                              <DropdownToggle
                                  caret
                                  color="white"
                                  className="text-capitalize m-1">
                                {
                                  this.state.ascending?label.Ascending:label.Descending
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={e=>(this.refresh(true, this.state.sortir))}>{label.Ascending}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(false, this.state.sortir))}>{label.Descending}</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Table responsive hover>
                              <thead>
                              <tr>
                                <th>#</th>
                                <th>{label.Type}</th>
                                <th>{label.Description}</th>
                                <th>{label.Status}</th>
                                <th>{label.Updated}</th>
                                <th>{label.Edit}</th>
                              </tr>
                              </thead>
                              <tbody>
                              {
                                this.state.chargerTypes.map((item, index) =>(
                                    <tr key={index}>
                                      <th scope="row">{++index}</th>
                                      <td>{item.type}</td>
                                      <td>{item.description}</td>
                                      <td><ActiveLabel trueLabel={label.Active} falseLabel={label.Inactive} active={item.active}/></td>
                                      <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                      <td>
                                        <Button color="primary" onClick={e=> {
                                          let chargerType = cloneObject(item)
                                          this.setState({
                                            chargerType: chargerType,
                                          })
                                        }}>
                                          <MdEdit/>
                                        </Button>
                                      </td>
                                    </tr>
                                ))
                              }
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Page>
    );
  }
}

export default Object.assign(ChargerTypePage, {ENDPOINT : "/chargerTypes"})
