import { STATE_LOGIN } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import AuthPage from './pages/AuthPage';
import { AuthProvider } from './utils/AuthContext';
import kitchen from '../src/utils/AxiosInstance';
// pages
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import './styles/reduction.scss';
import ProtectedRoute from 'components/Layout/ProtectedRoute';
import RolePage from './pages/RolePage';
import ConfigurationAdminPage from './pages/ConfigurationAdminPage';
import AdminPage from './pages/AdminPage';
import AdminDetailPage from './pages/AdminDetailPage';
import RoleMenuDetailPage from './pages/RoleMenuDetailPage';
import ProfilePage from './pages/ProfilePage';
import ProvincePage from './pages/ProvincePage';
import CityPage from './pages/CityPage';
import DistrictPage from './pages/DistrictPage';
import VillagePage from './pages/VillagePage';
import Global from './utils/Global';
import { storeData } from './utils/StorageUtil';
import AdminActivityPage from './pages/AdminActivityPage';
import UserActivityPage from './pages/UserActivityPage';
import MainDashboardPage from './pages/MainDashboardPage';
import './App.css';
import FormBuilderDetailPage from './pages/FormBuilderDetailPage';
import FormBuilderPage from './pages/FormBuilderPage';
import ModelPage from './pages/ProductPage';
import ModelDetailPage from './pages/ProductDetailPage';
import TransactionPage from './pages/TransactionPage';
import UserPage from './pages/UserPage';
import UserDetailPage from './pages/UserDetailPage';
import UserDevicePage from './pages/UserDevicePage';
import MainModelPage from './pages/MainModelPage';
import UserRoleMenuDetailPage from "./pages/UserRoleMenuDetailPage";
import ProductCategoryPage from "./pages/ProductCategoryPage";
import InspectionEventPage from "./pages/InspectionEventPage";
import AdminDevicePage from "./pages/AdminDevicePage";
import InspectionEventDetailPage from "./pages/InspectionEventDetailPage";
import 'antd/dist/antd.css';
import BannerPage from "./pages/BannerPage";
import CompanyPage from "./pages/CompanyPage";
import CompanyDetailPage from "./pages/CompanyDetailPage";
import ChargerTypePage from "./pages/ChargerTypePage";
import StationPage from "./pages/StationPage";
import StationDetailPage from "./pages/StationDetailPage";
import ChargerPage from "./pages/ChargerPage";
import ChargerDetailPage from "./pages/ChargerDetailPage";
import ChargerGunPage from "./pages/ChargerGunPage";
import ChargerGunTypePage from "./pages/ChargerGunTypePage";
import TransactionDetailPage from "./pages/TransactionDetailPage";
import SubPaymentMediaPage from "./pages/SubPaymentMediaPage";
import ApplicationVersionPage from "./pages/ApplicationVersionPage";
import QRCodePage from "./pages/QRCodePage";

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {


  componentDidMount() {
    fetchAppSetting()
  }


  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <AuthProvider>
          <GAListener>
            <Switch>
              <ProtectedRoute
                exact
                rootMenu={ModelPage.ENDPOINT}
                path={ModelPage.ENDPOINT}
                layout={MainLayout}
                component={ModelPage}
              />
              <ProtectedRoute
                exact
                rootMenu={ModelPage.ENDPOINT}
                path={ModelDetailPage.ENDPOINT}
                layout={MainLayout}
                component={ModelDetailPage}
              />
              <ProtectedRoute
                exact
                rootMenu={TransactionPage.ENDPOINT}
                path={TransactionPage.ENDPOINT}
                layout={MainLayout}
                component={TransactionPage}
              />
              <ProtectedRoute
                exact
                rootMenu={TransactionPage.ENDPOINT}
                path={TransactionDetailPage.ENDPOINT}
                layout={MainLayout}
                component={TransactionDetailPage}
              />


              <ProtectedRoute
                exact
                rootMenu={UserPage.ENDPOINT}
                path={UserPage.ENDPOINT}
                layout={MainLayout}
                component={UserPage}
              />
              <ProtectedRoute
                exact
                rootMenu={UserPage.ENDPOINT}
                path={UserDetailPage.ENDPOINT}
                layout={MainLayout}
                component={UserDetailPage}
              />
              <ProtectedRoute
                exact
                rootMenu={UserDevicePage.ENDPOINT}
                path={UserDevicePage.ENDPOINT}
                layout={MainLayout}
                component={UserDevicePage}
              />

              <ProtectedRoute
                exact
                path={ProductCategoryPage.ENDPOINT}
                rootMenu={ProductCategoryPage.ENDPOINT}
                layout={MainLayout}
                component={ProductCategoryPage}
              />
              <ProtectedRoute
                exact
                rootMenu={MainModelPage.ENDPOINT}
                path={MainModelPage.ENDPOINT}
                layout={MainLayout}
                component={MainModelPage}
              />
              <ProtectedRoute
                exact
                rootMenu={ProvincePage.ENDPOINT}
                path={ProvincePage.ENDPOINT}
                layout={MainLayout}
                component={ProvincePage}
              />
              <ProtectedRoute
                exact
                rootMenu={CityPage.ENDPOINT}
                path={CityPage.ENDPOINT}
                layout={MainLayout}
                component={CityPage}
              />
              <ProtectedRoute
                exact
                rootMenu={DistrictPage.ENDPOINT}
                path={DistrictPage.ENDPOINT}
                layout={MainLayout}
                component={DistrictPage}
              />
              <ProtectedRoute
                exact
                rootMenu={VillagePage.ENDPOINT}
                path={VillagePage.ENDPOINT}
                layout={MainLayout}
                component={VillagePage}
              />

              <ProtectedRoute
                exact
                rootMenu={RolePage.ENDPOINT}
                path={RolePage.ENDPOINT}
                layout={MainLayout}
                component={RolePage}
              />
              <ProtectedRoute
                exact
                rootMenu={RolePage.ENDPOINT}
                path={RoleMenuDetailPage.ENDPOINT}
                layout={MainLayout}
                component={RoleMenuDetailPage}
              />
              <ProtectedRoute
                exact
                rootMenu={ConfigurationAdminPage.ENDPOINT}
                path={ConfigurationAdminPage.ENDPOINT}
                layout={MainLayout}
                component={ConfigurationAdminPage}
              />
              <ProtectedRoute
                exact
                rootMenu={AdminPage.ENDPOINT}
                path={AdminPage.ENDPOINT}
                layout={MainLayout}
                component={AdminPage}
              />
              <ProtectedRoute
                exact
                rootMenu={AdminPage.ENDPOINT}
                path={AdminDetailPage.ENDPOINT}
                layout={MainLayout}
                component={AdminDetailPage}
              />
              <ProtectedRoute
                exact
                rootMenu={AdminDevicePage.ENDPOINT}
                path={AdminDevicePage.ENDPOINT}
                layout={MainLayout}
                component={AdminDevicePage}
              />
              <ProtectedRoute
                exact
                rootMenu={AdminActivityPage.ENDPOINT}
                path={AdminActivityPage.ENDPOINT}
                layout={MainLayout}
                component={AdminActivityPage}
              />
              <ProtectedRoute
                exact
                rootMenu={UserActivityPage.ENDPOINT}
                path={UserActivityPage.ENDPOINT}
                layout={MainLayout}
                component={UserActivityPage}
              />
              <LayoutRoute
                exact
                path={AuthPage.ENDPOINT}
                layout={EmptyLayout}
                component={props => (
                  <AuthPage {...props} authState={STATE_LOGIN} />
                )}
              />
              <ProtectedRoute
                  exact
                  rootMenu={"*"}
                  path={QRCodePage.ENDPOINT}
                  layout={EmptyLayout}
                  component={QRCodePage}
              />

              <ProtectedRoute
                exact
                rootMenu={MainDashboardPage.ENDPOINT}
                path={MainDashboardPage.ENDPOINT}
                layout={MainLayout}
                component={MainDashboardPage}
              />
              <ProtectedRoute
                exact
                rootMenu={"*"}
                path={ProfilePage.ENDPOINT}
                layout={MainLayout}
                component={ProfilePage}
              />

              <ProtectedRoute
                exact
                rootMenu={FormBuilderPage.ENDPOINT}
                path={FormBuilderPage.ENDPOINT}
                layout={MainLayout}
                component={FormBuilderPage}
              />
              <ProtectedRoute
                exact
                rootMenu={FormBuilderPage.ENDPOINT}
                path={FormBuilderDetailPage.ENDPOINT}
                layout={MainLayout}
                component={FormBuilderDetailPage}
              />

              <ProtectedRoute
                  exact
                  rootMenu={BannerPage.ENDPOINT}
                  path={BannerPage.ENDPOINT}
                  layout={MainLayout}
                  component={BannerPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={CompanyPage.ENDPOINT}
                  path={CompanyPage.ENDPOINT}
                  layout={MainLayout}
                  component={CompanyPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={CompanyPage.ENDPOINT}
                  path={CompanyDetailPage.ENDPOINT}
                  layout={MainLayout}
                  component={CompanyDetailPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={ChargerTypePage.ENDPOINT}
                  path={ChargerTypePage.ENDPOINT}
                  layout={MainLayout}
                  component={ChargerTypePage}
              />

              <ProtectedRoute
                  exact
                  rootMenu={StationPage.ENDPOINT}
                  path={StationDetailPage.ENDPOINT}
                  layout={MainLayout}
                  component={StationDetailPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={StationPage.ENDPOINT}
                  path={StationPage.ENDPOINT}
                  layout={MainLayout}
                  component={StationPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={ChargerPage.ENDPOINT}
                  path={ChargerPage.ENDPOINT}
                  layout={MainLayout}
                  component={ChargerPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={ChargerPage.ENDPOINT}
                  path={ChargerDetailPage.ENDPOINT}
                  layout={MainLayout}
                  component={ChargerDetailPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={ChargerGunPage.ENDPOINT}
                  path={ChargerGunPage.ENDPOINT}
                  layout={MainLayout}
                  component={ChargerGunPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={ChargerGunTypePage.ENDPOINT}
                  path={ChargerGunTypePage.ENDPOINT}
                  layout={MainLayout}
                  component={ChargerGunTypePage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={SubPaymentMediaPage.ENDPOINT}
                  path={SubPaymentMediaPage.ENDPOINT}
                  layout={MainLayout}
                  component={SubPaymentMediaPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={"/applicationVersions"}
                  path="/applicationVersions"
                  layout={MainLayout}
                  component={ApplicationVersionPage}
              />
              <Redirect to={MainDashboardPage.ENDPOINT} />
            </Switch>
          </GAListener>
        </AuthProvider>
      </BrowserRouter>
    );
  }
}
function fetchAppSetting() {
  get(Global.API.CONFIGURATION_CURRENT_ACTIVE, null, null, response => {
    if (response.data.code === 200) {
      storeData(Global.CONFIGURATION, response.data.data)
    }
  })
}

function get(url, params, config, callback) {
  kitchen.get(url, params, config).then(response => {
    callback(response)
  }).catch(e => {

  });
}




const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
