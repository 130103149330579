import React from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import {parseDate, sortirMap} from '../../utils/Utilities';
import Global, {DD_MM_YYYY_HH_MM_SS, TRANSACTION_STATUS_COLOR} from '../../utils/Global';
import queryString from 'query-string';
import SockJsClient from 'react-stomp';
import label from '../../message/Label'
import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";
import ModalPagination from "../ModalPagination";
import {transactionEventsFromNative} from "../../utils/MyUtilities";


export default class InspectionEventHistoryModal extends BaseComponent{

    constructor(props) {
        super(props);
        this.state ={
            ascending:false,
            inspection:this.props.inspection,
            key:queryString.parse(this.props.query).tab?queryString.parse(this.props.query).tab:label.All,
            totalPage:0,
            totalElement:0,
            pageElement:0,
            createdStartDate:null,
            createdEndDate:null,
            inspectionEvents:[],
            inspectionStatus:null,
            inspectionStatusId:null,
            sortir:'jie.updated',
            page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
            search:queryString.parse(this.props.query).search?queryString.parse(this.props.query).search:"",
            inputSearch : "",
        }
    }


    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props && props.showing && props.inspection && props.inspection.id){
            let page = queryString.parse(props.query).page?queryString.parse(props.query).page:1
            let key = queryString.parse(props.query).tab?queryString.parse(props.query).tab:label.All
            let search = queryString.parse(props.query).search?queryString.parse(props.query).search:""
            let inspectionStatusId = null
            if(key===label.All){
                inspectionStatusId = null
            }else{
                inspectionStatusId = key
            }
            this.setState({
                page:page,
                key:key,
                search:search,
                inspectionStatusId:inspectionStatusId,
                inspection:props.inspection,
            }, () => {
                this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, true)
            })
        }
    }

    fetchAll = (ascending, sortir, search, progressing, excelMode) =>{
        let params = {
            inspectionStatusId:this.state.inspectionStatusId,
            ascending:ascending,
            sortir:sortir,
            search:search,
            page:this.state.page-1,
            reference:this.state.inspection.reference,
            createdStartDate:this.state.createdStartDate,
            createdEndDate:this.state.createdEndDate,
            excelMode:excelMode

        }
        if(excelMode){
            this.asyncDownloadGet(Global.API.TRANSACTION_EVENTS, {params:params}, null,true);
            return
        }
        this.get(Global.API.TRANSACTION_EVENTS, {params:params}, null, res =>{
            if(res.code === 200){
                let inspectionEvents = transactionEventsFromNative(res.data);
                this.setState({
                    inspectionEvents : inspectionEvents,
                    totalPage: res.totalPage,
                    totalElement: res.totalElement,
                    pageElement: res.pageElement,
                })
            }
        }, progressing, true);
    }

    refreshInspections = (ascending, sortir, progressing) => (
        this.setState({
            ascending:ascending,
            sortir:sortir,
        }, () => {
            this.fetchAll(ascending, sortir, this.state.search, progressing)
        })
    )




    render() {
        let {brandCategories, brandCategory, totalElement, totalPage} = this.state

        return (
            <>
                {super.render()}
                <Modal
                    autoFocus={false}
                    style={{minWidth:'90%'}}
                    size="xl"
                    centered={true}
                    isOpen={this.props.showing}
                    backdrop={true}>
                    {super.render()}
                    <ModalHeader>
                        {label.CheckingHistory}
                    </ModalHeader>
                    <ModalBody>
                        {this.renderInspectionEvent()}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={e=>{
                                e.preventDefault()
                                this.props.onClose()
                            }}>
                            {label.Close}
                        </Button>
                    </ModalFooter>
                </Modal>

            </>
        )
    }

    renderInspectionEvent = () =>{
        return (
            <Row key={2}>
                <Col>
                    <Card>
                        <CardHeader>
                            <strong>{label.CheckingActivity}</strong>
                            <br/><br/>
                            <Row>
                                <Col md={3}>
                                    {label.SortBy} :
                                    <UncontrolledButtonDropdown key={1}>
                                        <DropdownToggle
                                            caret
                                            color="white"
                                            className="text-capitalize m-1">
                                            {
                                                sortirMap(this.state.sortir.toString())
                                            }
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={e=>(this.refreshInspections(this.state.ascending, "jie.serialCode", true))}>{label.SerialCode}</DropdownItem>
                                            <DropdownItem onClick={e=>(this.refreshInspections(this.state.ascending, "jie.performerName", true))}>{label.PerformedBy}</DropdownItem>
                                            <DropdownItem onClick={e=>(this.refreshInspections(this.state.ascending, "jie.created", true))}>{label.Created}</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>
                                <Col md={3}>
                                    {label.Sortir} :
                                    <UncontrolledButtonDropdown key={2}>
                                        <DropdownToggle
                                            caret
                                            color="white"
                                            className="text-capitalize m-1">
                                            {
                                                this.state.ascending?label.Ascending:label.Descending
                                            }
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={e=>(this.refreshInspections(true, this.state.sortir, true))}>{label.Ascending}</DropdownItem>
                                            <DropdownItem onClick={e=>(this.refreshInspections(false, this.state.sortir, true))}>{label.Descending}</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>
                                <Col md={6}>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {this.renderInspectionEventTable()}
                        </CardBody>
                        <SockJsClient
                            url={Global.BASE_URL}
                            topics={[Global.API.LISTEN_TRANSACTION]}
                            autoReconnect={true}
                            debug={Global.DEBUG}
                            heartbeat={5000}
                            onMessage={(message) => {
                                this.successToast(message)
                                this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, true)
                            }}
                            onConnectFailure={(object)=>{
                                console.error(object)
                            }}
                            ref={ (client) => { this.clientRef = client }} />
                    </Card>
                </Col>
            </Row>
        )
    }


    renderInspectionEventTable = () =>{
        return (
            <Card>
                <CardBody>
                    <Table responsive hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{label.ReferenceCode}</th>
                            <th>{label.SerialCode}</th>
                            <th style={{width:'20%'}}>{label.Customer}</th>
                            <th>{label.SystemType}</th>
                            <th>{label.Category}</th>
                            <th>{label.PerformedBy}</th>
                            <th style={{width:'15%'}}>{label.Status}</th>
                            <th>{label.Created}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.inspectionEvents.map((item, index)=>(
                                <tr key={item.id}>
                                    <th scope="row">{((this.state.page-1)* this.state.pageElement)+(++index)}</th>
                                    <td><span>{item.inspection.reference}</span></td>
                                    <td><span>{item.serialCode}</span></td>
                                    <td>
                                        {label.OrderNo} : {item.customerOrderNo}<br/>
                                        {label.Client} : {item.client}<br/>
                                        {label.ProductSKU} : {item.productSKU}<br/>
                                        {label.ProductName} : {item.productName}<br/>
                                    </td>
                                    <td>{item.inspection.model.systemType}</td>
                                    <td>{item.inspection.model.productCategory.name}</td>
                                    <td>
                                        {
                                            item.user?(
                                                    <a href={'/userDetail?id='+item.user.id}>{item.performerName}&nbsp;-&nbsp;{item.performerRole}</a>
                                                ):
                                                (<span>{item.performerName}&nbsp;-&nbsp;{item.performerRole}</span>)
                                        }
                                    </td>
                                    <td>
                                        <span
                                            style={
                                                {
                                                    background:TRANSACTION_STATUS_COLOR[item.inspectionStatus.name],
                                                    paddingLeft:'8px', paddingRight:'8px', paddingTop:'4px', paddingBottom:'4px', color:'white',
                                                    fontWeight:'bold', borderRadius:'8px'
                                                }
                                            }>
                                            {item.inspectionStatus.label}
                                        </span>
                                    </td>
                                    <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </CardBody>
                <ModalPagination {...this.props} currentPage={this.state.page} pageCount={this.state.totalPage} onSelect={(page)=>{
                    this.setState({
                        page:page
                    }, () => {
                        this.refreshInspections(this.state.ascending, this.state.sortir, true)
                    })
                }}/>
            </Card>
        )
    }


}

InspectionEventHistoryModal.propTypes = {
    showing:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    inspection:PropTypes.object.isRequired
}
