import Page from 'components/Page';
import React from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import BasePage from './BasePage';
import Global, {DD_MM_YYYY_HH_MM_SS} from '../utils/Global';
import {MdDelete, MdEdit} from 'react-icons/md';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import SearchInput from '../components/SearchInput';
import Pagination from '../components/Pagination';
import queryString from 'query-string';
import {deleteParam, parseDate, sortirMap} from '../utils/Utilities';
import ActiveLabel from '../components/Widget/ActiveLabel';
import Img from 'react-image';
import label from '../message/Label'
import CompanyDetailPage from "./CompanyDetailPage";
import StationDetailPage from "./StationDetailPage";
import ChargerDetailPage from "./ChargerDetailPage";

const tableTypes = ['', 'bordered', 'striped', 'hover'];

class ChargerPage extends BasePage{


  constructor(props) {
    super(props);
    this.state ={
      chargers : [],
      charger:{},
      modalDeleteConfirm:false,
      ascending:false,
      sortir:'jt.created',
      search:"",
      page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
      totalPage:0,
      totalElement:0,
      pageElement:0,
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, true)
  }
    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            this.setState({
                page:queryString.parse(props.query).page?queryString.parse(props.query).page:1
            }, () => {
                this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, true)
            })
        }
    }



    fetchAll = (ascending, sortir, search, page, progressing) => {
        this.get(Global.API.CHARGERS, {
            params:{
                ascending:ascending,
                sortir:sortir,
                search:search,
                page: page-1
            }
        }, null, res=>{
            if(res.code === 200){
                this.setState({
                    chargers : res.data,
                    totalPage: res.totalPage,
                    totalElement: res.totalElement,
                    pageElement: res.pageElement,
                })
            }
        },progressing, true);
    }

  confirmDelete = () =>{
    this.setState({
      modalDeleteConfirm:true
    })
  }

  delete = () => {
    this.setState({
      modalDeleteConfirm:false
    }, () => {
      this.get(Global.API.CHARGER_DELETE+"/"+this.state.charger.id, null, null, res=>{
          if(res.code===200){
              this.successToast(res.message)
              this.setState({
                  category:null
              }, () => {
                  this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, true)
              })
          }
      }, true, true);
    })
  }

  closeDialog = () =>{
    this.setState({
      modalAddConfirm:false,
      modalDeleteConfirm:false
    })
  }

  refreshCharger = (ascending, sortir, progressing) => (
      this.setState({
          ascending:ascending,
          sortir:sortir
      }, () => {
          this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, progressing)
      })
  )

    render() {
        var i = 0 ;

    return (
        <Page
            title={label.Chargers}
            breadcrumbs={[{ name: 'charger', active: true }]}
            className="TablePage"
        >
            {super.render()}
          <ConfirmDialog
              showing={this.state.modalDeleteConfirm}
              title={label.DeleteConfirmationTitle}
              message="Do you want to delete this charger item from your list ?"
              okCallback={this.delete}
              cancelCallback={this.closeDialog}/>
          <Row key={1}>
            <Col>
              <Card className="mb-6">
                <CardHeader>{label.Chargers}</CardHeader>
                <CardBody>
                  <Row>
                        <Col md={3}>
                            {label.SortBy} :
                            <UncontrolledButtonDropdown key={1}>
                                <DropdownToggle
                                    caret
                                    color="white"
                                    className="text-capitalize m-1">
                                    {
                                        sortirMap(this.state.sortir)
                                    }
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={e=>(this.refreshCharger(this.state.ascending, "jt.name", true))}>{label.Name}</DropdownItem>
                                    <DropdownItem onClick={e=>(this.refreshCharger(this.state.ascending, "jt.task_count", true))}>{label.TaskCount}</DropdownItem>
                                    <DropdownItem onClick={e=>(this.refreshCharger(this.state.ascending, "jt.created", true))}>{label.Created}</DropdownItem>
                                    <DropdownItem onClick={e=>(this.refreshCharger(this.state.ascending, "jt.updated", true))}>{label.Updated}</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </Col>
                        <Col md={3}>
                            {label.Sortir} :
                            <UncontrolledButtonDropdown key={2}>
                                <DropdownToggle
                                    caret
                                    color="white"
                                    className="text-capitalize m-1">
                                    {
                                        this.state.ascending?label.Ascending:label.Descending
                                    }
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={e=>(this.refreshCharger(true, this.state.sortir, true))}>{label.Ascending}</DropdownItem>
                                    <DropdownItem onClick={e=>(this.refreshCharger(false, this.state.sortir, true))}>{label.Descending}</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </Col>
                        <Col md={4}>
                            <SearchInput
                                placeholder={label.TypeAndEnterToSearch}
                                value={this.state.search}
                                onChange={e=>{
                                    this.setState({
                                        search:e.target.value
                                    }, () => {
                                        if(this.state.search===''){
                                            deleteParam(this.props, 'page')
                                        }
                                    })
                                }}
                                onEnter={e=>{
                                    if(this.state.search!==''){
                                        deleteParam(this.props, 'page')
                                    }
                                }}
                            />
                        </Col>
                        <Col md={2}>
                            <ButtonGroup className="float-right">
                                <Button color="primary" onClick={e=>{
                                    this.props.history.push('/chargerDetail')
                                }}>{label.AddCharger}</Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                  <Row>
                      <Table responsive hover>
                          <thead>
                          <tr>
                              <th>#</th>
                              <th>{label.Name}</th>
                              <th>{label.Code}</th>
                              <th>{label.Label}</th>
                              <th>{label.Type}</th>
                              <th>{label.Company}</th>
                              <th>{label.Station}</th>
                              <th>{label.Status}</th>
                              <th>{label.Created}</th>
                              <th>{label.Updated}</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                              this.state.chargers.map((item, index)=>(
                                  <tr key={index}>
                                      <th scope="row">{((this.state.page-1)* this.state.pageElement)+(++index)}</th>
                                      <td><a href={ChargerDetailPage.ENDPOINT+"?id="+item.id}>{item.name}</a></td>
                                      <td>{item.code}</td>
                                      <td>{item.label}</td>
                                      <td>{item.chargerType.type}</td>
                                      <td><a href={CompanyDetailPage.ENDPOINT+"?id="+(item.company?item.company.id:undefined)}>{item.company?item.company.fullName:""}</a></td>
                                      <td><a href={StationDetailPage.ENDPOINT+"?id="+(item.station?item.station.id:undefined)}>{item.station?item.station.name:""}</a></td>
                                      <td><ActiveLabel active={item.active}/></td>
                                      <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                      <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>

                                  </tr>
                              ))
                          }
                          </tbody>
                      </Table>
                  </Row>
                </CardBody>
                  <Pagination {...this.props} currentPage={parseInt(this.state.page)} pageCount={this.state.totalPage} />
              </Card>
            </Col>
          </Row>
        </Page>
    );
  }

};


export default Object.assign(ChargerPage, {ENDPOINT : "/chargers"})
