import React from 'react';
import {Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Input from 'reactstrap/es/Input';
import ItemOption from '../Widget/ItemOption';
import Global from '../../utils/Global';
import BaseComponent from '../BaseComponent';
import {statusColor} from '../../utils/Utilities';
import label from '../../message/Label'
/*= (message, okCallback, cancelCallback) =>*/
export default class UpdateChargerStatusModal extends BaseComponent{

    constructor(props){
        super(props);
        let state = this.state
        state.modal= this.props.modal
        state.backdrop= false
        state.charger = this.props.charger
        state.chargerStatus = {}
        state.chargerStatuses = []
        state.newChargerStatus = {}
        state.okCallback = this.props.okCallback
        state.closeCallback = this.props.closeCallback
        state.note = ""
        state.resiCode = ""
        state.resiCodeMessage=""
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        let modal = this.state.modal
        if(modal!=props.modal){
            this.setState({
                modal:props.modal,
                charger: props.charger,
                okCallback : props.okCallback,
                closeCallback : props.closeCallback,
                chargerStatus:{},
                chargerStatuses:[],
                newChargerStatus:{},
                note:"",
                resiCode : "",
                resiCodeMessage : "",
            }, () => {
                if(props.modal && modal!==props.modal){
                    this.fetchChargerStatus()
                }
            })
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchChargerStatus()

    }

    fetchChargerStatus = () =>{
        let charger = this.state.charger?this.state.charger:{}
        let chargerStatus = charger.chargerStatus?charger.chargerStatus:{}
        this.get(Global.API.CHARGER_STATUSES, null, null, response=>{
            if(response.code===200){
                let chargerStatuses = response.data
                let chargerStatusOptions = []
                chargerStatuses.map((item, index)=>{
                    if(item.id!=chargerStatus.id){
                        chargerStatusOptions.push(item)
                    }
                })
                this.setState({
                    chargerStatuses : chargerStatusOptions
                })
            }
        }, true, true)
    }


    updateChargerStatus=()=>{
        let charger = this.state.charger?this.state.charger:{}
        let chargerStatus = charger.chargerStatus?charger.chargerStatus:{}
        let newChargerStatus = this.state.newChargerStatus?this.state.newChargerStatus:{}
        let note = this.state.note?this.state.note:""
        // let resiCode = this.state.resiCode?this.state.resiCode:""
        let confirmationMessage = "";
        // if(newChargerStatus.id===_DELIVERY){
        //     confirmationMessage= "Do you want to update this charger status from = "+chargerStatus.name+" to = "+newChargerStatus.name+" with resi code is "+resiCode+" ? "
        // }else{
        // }
        confirmationMessage= "Do you want to update this charger status from = "+chargerStatus.name+" to = "+newChargerStatus.name+" ? "
        this.openConfirmDialog(label.Confirmation, confirmationMessage, ()=>{
            let formData = new FormData();
            formData.append("chargerId", charger.id)
            formData.append("chargerStatusId", newChargerStatus.id)
            formData.append("note", note)
            this.post(Global.API.CHARGER_UPDATE_STATUS, formData, null, response=>{
                if(response.code===200){
                    let charger = response.data
                    this.successToast(response.message)
                    this.setState({
                        modal:false
                    }, () => {
                        if(this.state.okCallback){
                            this.state.okCallback(charger.chargerStatus)
                        }
                    })
                }
            }, true, true)
        })
    }

    render(){
        let charger = this.state.charger?this.state.charger:{}
        let chargerStatus = charger.chargerStatus?charger.chargerStatus:{}
        let chargerStatuses = this.state.chargerStatuses?this.state.chargerStatuses:[]
        let newChargerStatus = this.state.newChargerStatus?this.state.newChargerStatus:{}

        return (
            <Modal size="lg" centered={true}
                isOpen={this.state.modal}
                backdrop={false}>
                {super.render()}
                <ModalHeader>
                    Update Charger Status : {charger&&charger.refCode}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="currentStatus">Current Status</Label>
                                <Input
                                    style={{fontSize:'bold', color:statusColor(chargerStatus.id), borderColor: statusColor(chargerStatus.id)}}
                                    id="currentStatus"
                                    type="text"
                                    name="currentStatus"
                                    value={chargerStatus.name}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <ItemOption
                                    title={label.SelectStatus}
                                    fieldForLabel={"label"}
                                    objects={chargerStatuses}
                                    default={newChargerStatus.id}
                                    callback={(newChargerStatus)=>{
                                        if(newChargerStatus!=null){
                                            this.setState({
                                                newChargerStatus:newChargerStatus
                                            })
                                        }else{
                                            this.setState({
                                                newChargerStatus:{}
                                            })
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <FormGroup>
                            <Label for="note">Note</Label>
                            <Input
                                id="note"
                                type="text"
                                name="note"
                                value={this.state.note}
                                onChange={(e) =>{
                                    let value = e.target.value
                                    this.setState({
                                        note : value?value:null
                                    })
                                }}
                                placeholder={label.EnterNoteRecommended}
                            />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Row>
                        {
                            newChargerStatus.id&&(
                                <Col>
                                    <Button color="primary" onClick={e=>{
                                        this.updateChargerStatus()
                                    }}>
                                        Update
                                    </Button>
                                </Col>
                            )
                        }
                        <Col>
                            <Button color="secondary" onClick={e=>{
                                e.preventDefault()
                                this.setState({
                                    modal:false
                                })
                                if(this.state.closeCallback){
                                    this.state.closeCallback()
                                }
                            }}>
                                {label.Cancel}
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        )
    }
}

UpdateChargerStatusModal.propTypes = {
    modal:PropTypes.bool,
    charger:PropTypes.object,
    okCallback:PropTypes.func,
    closeCallback:PropTypes.func,
}
