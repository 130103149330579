import Page from 'components/Page';
import React from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import shoppingBag from 'assets/img/products/shopping-bag.png';
import CardBlock from 'reactstrap/es/CardBlock';
import BasePage from './BasePage';
import Global, {CATEGORY_IMAGE_MAX_FILE_SIZE, DD_MM_YYYY_HH_MM_SS, MIME_PNG_JPEG} from '../utils/Global';
import {allIsEmpty, cloneObject, imageSelector, isEmpty, parseDate, sortirMap} from '../utils/Utilities';
import {MdEdit} from 'react-icons/md';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import ActiveOption from '../components/Widget/ActiveOption';
import ActiveLabel from '../components/Widget/ActiveLabel';
import WarningLabel from '../components/Widget/WarningLabel';
import label from '../message/Label'
import MyCardImage from "../components/Card/MyCardImage";
import Image from "../components/Widget/Image";

const tableTypes = ['', 'bordered', 'striped', 'hover'];


class ChargerGunTypePage extends BasePage{
  
  constructor(props) {
    super(props);
    this.state = {
      chargerGunTypes : [],
      chargerGunType:{},
      modalAddConfirm:false,
      modalDeleteConfirm:false,
      image : undefined,
      modal: false,
      ascending:true,
      sortir:'created',
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchAll()
  }

  confirmSave = () =>{
    var chargerGunType = this.state.chargerGunType
    if(allIsEmpty(chargerGunType.type)){
      this.showDialog(label.Warning, label.PleaseCompleteTheFields)
    }else{
      this.setState({
        modalAddConfirm:true
      })
    }
  }


  save = () => {
    let chargerGunType = this.state.chargerGunType
    this.setState({
      modalAddConfirm:false
    }, () => {
      this.post(Global.API.CHARGER_GUN_TYPE_SAVE, chargerGunType, null, res=>{
        if(res.code===200){
          this.setState({
            chargerGunType:{},
            image:undefined
          }, () => {
            this.showDialog(label.Success, res.message)
            this.fetchAll()
          })
        }
      },  true, true)
    })
  }


  confirmDelete = () =>{
    this.setState({
      modalDeleteConfirm:true
    })
  }

  closeDialog = () =>{
    this.setState({
      modalAddConfirm:false,
      modalDeleteConfirm:false
    })
  }

  fetchAll = () => {
    this.get(Global.API.CHARGER_GUN_TYPES, {
      params:{
        ascending:this.state.ascending,
        sortir:this.state.sortir,
      }
    }, null, response=>{
      if(response.code === 200){
        this.setState({
          chargerGunTypes : response.data
        })
      }
    }, true, true);
  }

  refresh = (ascending, sortir) => (
      this.setState({
        ascending:ascending,
        sortir:sortir
      }, () => {
        this.fetchAll()
      })
  )


  render() {
    let { chargerGunType} = this.state

    return (
        <Page
            title={label.ChargerGunType}
            breadcrumbs={[{ name: label.ChargerGunType, active: true }]}
            className="TablePage">
          {super.render()}
          <ConfirmDialog
              showing={this.state.modalAddConfirm}
              title={label.UpdateConfirmation}
              message={label.UpdateConfirmation}
              okCallback={this.save}
              cancelCallback={this.closeDialog}/>
          <ConfirmDialog
              showing={this.state.modalDeleteConfirm}
              title={label.DeleteConfirmationTitle}
              message={label.DeleteConfirmationMessage}
              okCallback={this.delete}
              cancelCallback={this.closeDialog}/>
          <Row key={1}>
            <Col>
              <Card>
                <CardHeader>{label.ChargerGunType}</CardHeader>
                <CardBody >
                  <Row>
                    <Col md={3}>
                      <Card>
                        <CardHeader>{label.Form}</CardHeader>
                        <CardBody>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label for="type">{label.Type}</Label>
                                <Input
                                    type="text"
                                    name="type"
                                    value={!isEmpty(chargerGunType.type)?chargerGunType.type:""}
                                    onChange={(e) =>{
                                      if(chargerGunType){
                                        chargerGunType.type = e.target.value
                                        this.setState({
                                          chargerGunType:chargerGunType
                                        })
                                      }
                                    }}
                                    placeholder={label.EnterChargerGunType}
                                />
                              </FormGroup>
                              <FormGroup>
                                <Label for="description">{label.Description}</Label>
                                <Input
                                    type="textarea"
                                    name="description"
                                    value={(chargerGunType.description)?chargerGunType.description:""}
                                    onChange={(e) =>{
                                      if(chargerGunType){
                                        chargerGunType.description = e.target.value
                                        this.setState({
                                          chargerGunType:chargerGunType
                                        })
                                      }
                                    }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonGroup className="float-left">
                                <Button color="danger" onClick={e => (
                                    this.setState({
                                      chargerGunType:{}
                                    })
                                )}>{label.Cancel}</Button>
                              </ButtonGroup>
                            </Col>
                            <Col>
                              <ButtonGroup className="float-right">
                                <Button color="primary" onClick={e=>(
                                    this.confirmSave()
                                )}> {
                                  (this.state.chargerGunType!=null && this.state.chargerGunType.id!=null)?label.Update:label.Add
                                } </Button>

                              </ButtonGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={9}>
                      <Card body>
                        <Row>
                          <Col>
                            {label.SortBy} :
                            <UncontrolledButtonDropdown key={1}>
                              <DropdownToggle
                                  caret
                                  color="white"
                                  className="text-capitalize m-1">
                                {
                                  sortirMap(this.state.sortir)
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "name"))}>{label.Name}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "created"))}>{label.Created}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "updated"))}>{label.Updated}</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Col>
                          <Col>
                            {label.Sortir} :
                            <UncontrolledButtonDropdown key={2}>
                              <DropdownToggle
                                  caret
                                  color="white"
                                  className="text-capitalize m-1">
                                {
                                  this.state.ascending?label.Ascending:label.Descending
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={e=>(this.refresh(true, this.state.sortir))}>{label.Ascending}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(false, this.state.sortir))}>{label.Descending}</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Table responsive hover>
                              <thead>
                              <tr>
                                <th>#</th>
                                <th>{label.Type}</th>
                                <th>{label.Description}</th>
                                <th>{label.Created}</th>
                                <th>{label.Updated}</th>
                                <th>{label.Edit}</th>
                              </tr>
                              </thead>
                              <tbody>
                              {
                                this.state.chargerGunTypes.map((item, index) =>(
                                    <tr key={index}>
                                      <th scope="row">{++index}</th>
                                      <td>{item.type}</td>
                                      <td>{item.description}</td>
                                      <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                      <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                      <td>
                                        <Button
                                            color="primary"
                                            onClick={e=> {
                                              let chargerGunType = cloneObject(item)
                                              this.setState({
                                                chargerGunType: chargerGunType
                                            })
                                          }}>
                                          <MdEdit/>
                                        </Button>
                                      </td>
                                    </tr>
                                ))
                              }
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>


        </Page>
    );
  }
}

export default Object.assign(ChargerGunTypePage, {ENDPOINT : "/chargerGunTypes"})
