import React from 'react';
import BasePage from './BasePage';
import axios from 'axios';

import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    CardImg,
    CardTitle,
    Col,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
} from 'reactstrap';
import shoppingBag from 'assets/img/products/shopping-bag.png';
import Page from '../components/Page';
import ItemOption from '../components/Widget/ItemOption';
import Global, {DEFAULT_LOCATION, MIME_PNG_JPEG, STATION_IMAGE_MAX_FILE_SIZE} from '../utils/Global';
import {allIsEmpty, imageSelector, isEmpty, parseDate} from '../utils/Utilities';
import queryString from 'query-string';
import ConfirmDialog from '../components/modal/ConfirmDialog';
// import InventoryQuantityModal from '../components/modal/InventoryQuantityModal';
import {getData} from '../utils/StorageUtil';
import ImageCropper from '../components/modal/ImageCropper';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import WarningLabel from '../components/Widget/WarningLabel';
import DateInput from '../components/Widget/DateInput';
import NumberInput from '../components/Widget/NumberInput';
import label from '../message/Label'
import {MapContainer} from "../components/Widget/MapContainer";
import Checkbox from "../components/Checkbox";
import {TimePicker} from "antd";
import moment from "moment";
import {MdCode, IoMdKey} from "react-icons/all";
import ActiveOption from "../components/Widget/ActiveOption";
import CardBlock from "reactstrap/es/CardBlock";


class StationDetailPage extends BasePage {

    constructor(props) {
        super(props);
        let state = this.state
        state.station = {}
        state.stationType = {}
        state.station = null
        state.categories = []
        state.image = null
        state.provinces = []
        state.cities = []
        state.districts = []
        state.villages = []
        state.province = {}
        state.city = {}
        state.district = {}
        state.village = {}
        state.stationIdQuery = queryString.parse(this.props.query).id
        state.weeklyOpens = []
        state.category = {}
        state.modalAddConfirm = false
        state.modalDeleteConfirm = false
        state.inventories = []
        state.inventory = null
        state.ascending = true
        state.sortir = 'created'
        state.quantityModal = false
        state.page = queryString.parse(this.props.query).page ? queryString.parse(this.props.query).page : 1
        state.totalPage = 0
        state.totalElement = 0
        state.pageElement = 0
        state.validation = {}
        this.setState(state)
        this.fetchProvinces((provinces) => this.setState({provinces: provinces}))

    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchStationDetail(this.state.stationIdQuery)
    }

    componentWillReceiveProps(props, nextContext) {
        if (props != this.props) {
            this.setState({
                page: queryString.parse(props.query).page ? queryString.parse(props.query).page : 1
            }, () => {
                // this.fetchAllInventories(this.state.station.id, this.state.ascending, this.state.sortir, true)
            })
        }

    }


    confirmSave = () => {
        let station = this.state.station
        let validation = this.state.validation
        let modalAddConfirm = true
        // let weeklyOpens = station.id ? station.weeklyOpens : []
        let weeklyOpens = this.state.weeklyOpens? this.state.weeklyOpens : []

        weeklyOpens.forEach((value, index) => {
            if (value.open && !value._24Hours && !value.openTime) {
                weeklyOpens[index].openErrorMessage = label.EnterOpenTime
                modalAddConfirm = false;
            }
            if (value.open && !value._24Hours && !value.closeTime) {
                weeklyOpens[index].closeErrorMessage = label.EnterCloseTime
                modalAddConfirm = false;
            }
        })

        if (isEmpty(station.name)) {
            validation.name = label.EnterName
            modalAddConfirm = false;
        }
        if (isEmpty(station.description)) {
            validation.description = label.EnterDescription
            modalAddConfirm = false;
        }
        if (isEmpty(station.code) || !isEmpty(validation.code)) {
            validation.code = validation.code?validation.code:label.GenerateCode
            modalAddConfirm = false;
        }
        if (isEmpty(station.buildDate)) {
            validation.buildDate = label.EnterBuildDate
            modalAddConfirm = false;
        }
        if (isEmpty(station.phoneNumber)) {
            validation.phoneNumber = label.EnterPhoneNumber
            modalAddConfirm = false;
        }
        if (isEmpty(station.address)) {
            validation.address = label.EnterAddress
            modalAddConfirm = false;
        }
        if (isEmpty(station.village)) {
            validation.village = label.SelectVillage
            modalAddConfirm = false;
        }
        this.setState({
            weeklyOpens: weeklyOpens,
            modalAddConfirm: modalAddConfirm,
            validation:validation
        })

    }

    confirmDelete = () => {
        this.setState({
            modalDeleteConfirm: true
        })
    }

    delete = () => {
        if (this.state.inventory != null) {
            this.setState({
                modalDeleteConfirm: false
            }, () => {
                this.get(Global.API.INVENTORY_DELETE + "/" + this.state.inventory.id, null, null, res => {
                    if (res.code === 200) {
                        this.setState({
                            inventory: null
                        }, () => {
                            // this.fetchAllInventories(this.state.station.id, this.state.ascending, this.state.sortir, true)
                        })
                    }
                }, true, true);
            })
        }
    }

    saveUpload = () => {
        let station = this.state.station;
        let weeklyOpens = this.state.weeklyOpens
        station.weeklyOpens = weeklyOpens
        var formData = new FormData();

        formData.append("stationDtoGson", JSON.stringify(station))
        formData.append("multipartFile1", this.state.imageBlob1);
        formData.append("multipartFile2", this.state.imageBlob2);
        formData.append("multipartFile3", this.state.imageBlob3);
        formData.append("multipartFile4", this.state.imageBlob4);
        this.setState({
            modalAddConfirm: false
        }, () => {
            this.post(Global.API.STATION_SAVE_UPLOAD, formData, null, res => {
                if (res.code === 200) {
                    this.setState({
                        station: res.data
                    }, () => {
                        this.showDialog("Success", res.message)
                        this.fetchStationDetail(this.state.station.id)
                        // changeParam(this.props, 'id', this.state.station.id)
                    })
                }
            }, true, true)
        })
    }


    fetchStationDetail = (id) => {
        if (id) {
            this.get(Global.API.STATION, {
                params: {
                    id: id
                }
            }, null, response => {
                if (response.code === 200) {
                    let station = response.data
                    // let station = station.station
                    if (!station.latitude || !station.longitude) {
                        station.latitude = DEFAULT_LOCATION.latitude
                        station.longitude = DEFAULT_LOCATION.longitude
                    }
                    let weeklyOpens = station.weeklyOpens ? station.weeklyOpens : []
                    let village = station && station.village ? station.village : null
                    let district = station && station.village ? station.village.district : null
                    let city = station && station.village ? station.village.district.city : null
                    let province = station && station.village ? station.village.district.city.province : null

                    this.setState({
                        station: station,
                        weeklyOpens: weeklyOpens,
                        taskCategory: response.data.taskCategory,
                        image1: station.imageLink1,
                        image2: station.imageLink2,
                        image3: station.imageLink3,
                        image4: station.imageLink4,
                        village: village,
                        district: district,
                        city: city,
                        province: province,
                    }, () => {
                        this.fetchWeeklyOpens()
                        if (this.state.village) {
                            this.fetchCities(this.state.province.id, cities => {
                                this.setState({
                                    cities: cities
                                })
                            })
                        }
                        if (this.state.city) {
                            this.fetchDistricts(this.state.city.id, districts => {
                                this.setState({
                                    districts: districts
                                })
                            })
                        }
                        if (this.state.district) {
                            this.fetchVillages(this.state.district.id, villages => {
                                this.setState({
                                    villages: villages
                                })
                            })
                        }
                        // this.fetchAllInventories(this.state.station.id, this.state.ascending, this.state.sortir, true)
                    })
                } else {
                    this.props.history.goBack();
                }
            }, true, true)
        }else{
            this.fetchWeeklyOpens()
        }
    }

    fetchWeeklyOpens(){
        let {station} = this.state
        if(!station){
            station = {}
        }
        this.get(Global.API.WEEKLY_OPENS, {
            params:{
                stationId:station.id
            }
        }, null, response=>{
            if(response.code===200){
                let weeklyOpens = response.data?response.data:[]
                this.setState({weeklyOpens})
            }
        }, false, false)
    }
    closeDialog = () => {
        this.setState({
            modalAddConfirm: false,
            modalDeleteConfirm: false,
        })
    }

    generateCode(){
        let {station, validation} = this.state
        if(!station){
            station = {}
        }
        this.get(Global.API.STATION_GENERATE_CODE, {
            params:{
                stationId:station.id
            }
        }, null, response=>{
            if(response.code===200){
                station.code = response.data
                validation.code = undefined
                this.setState({station, validation})
            }
        }, true, true)
    }
    validateCode(){
        let {station, validation} = this.state
        if(!station || !station.code){
            return;
        }
        this.get(Global.API.STATION_VALIDATE_CODE, {
            params:{
                stationId:station.id,
                code:station.code
            }
        }, null, response=>{
            if(response.code===200){
                let valid = response.data
                if(!valid){
                    validation.code = label.CodeAlreadyBeenUsed
                }else{
                    validation.code = undefined
                }
                this.setState({validation})
            }
        }, false, true)
    }




    render() {
        var ids = [];
        var labels = [];

        let configuration = getData(Global.CONFIGURATION)
        let aspect = 4 / 4
        if (configuration.stationImageDimensionWidth && configuration.stationImageDimensionHeight) {
            aspect = configuration.stationImageDimensionWidth / configuration.stationImageDimensionHeight
        }
        let {station, weeklyOpens, validation} = this.state
        if(!station){
            station = {}
        }
        if (!station.latitude || !station.longitude) {
            station.latitude = DEFAULT_LOCATION.latitude
            station.longitude = DEFAULT_LOCATION.longitude
        }


            return (
                <Page
                    title="Station Form"
                    breadcrumbs={[{name: 'station form', active: true}]}>
                    {super.render()}
                    <ConfirmDialog
                        showing={this.state.modalAddConfirm}
                        title={label.UpdateConfirmation}
                        message="Do you want to update this station item to your list ?"
                        okCallback={this.saveUpload}
                        cancelCallback={this.closeDialog}/>
                    <ConfirmDialog
                        showing={this.state.modalDeleteConfirm}
                        title={label.DeleteConfirmationTitle}
                        message="Do you want to delete this station item from your list ?"
                        okCallback={this.delete}
                        cancelCallback={this.closeDialog}/>

                    <Row key={1}>
                        <Col>
                            <Card className="mb-6">
                                <CardHeader>{label.Station}</CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md={3}>
                                            <Card>
                                                <CardImg top src={
                                                    this.state.image1 != null ? this.state.image1 : shoppingBag}
                                                         onClick={e => {
                                                             imageSelector(file => {
                                                                 this.setState({
                                                                     image1: file,
                                                                     cropperModal1: true
                                                                 })
                                                             }, MIME_PNG_JPEG, STATION_IMAGE_MAX_FILE_SIZE).click()
                                                         }}
                                                         onError={(elm) => this.defaultImage(elm, shoppingBag)}/>
                                                <CardBody>
                                                    <CardTitle>Station Image</CardTitle>
                                                    <Row>
                                                        <Col>
                                                            <WarningLabel
                                                                message={"*Use PNG or Jpeg Format / Max " + STATION_IMAGE_MAX_FILE_SIZE + " Kb"}/>
                                                        </Col>
                                                    </Row>
                                                    <br/>
                                                    <Row>
                                                        <Col>
                                                            <ButtonGroup className="float-left">
                                                                <Button color="danger" onClick={e => (
                                                                    this.setState({
                                                                        station: null,
                                                                        image1: null
                                                                    })
                                                                )}>{label.Remove}</Button>
                                                            </ButtonGroup>
                                                        </Col>
                                                        <Col>
                                                            <ButtonGroup className="float-right">
                                                                <Button color="primary" onClick={e => {
                                                                    imageSelector(file => {
                                                                        this.setState({
                                                                            image1: file,
                                                                            cropperModal1: true
                                                                        })
                                                                    }, MIME_PNG_JPEG, STATION_IMAGE_MAX_FILE_SIZE).click()
                                                                }}>Upload</Button>

                                                            </ButtonGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={3}>
                                            <Card>
                                                <CardImg top src={
                                                    this.state.image2 != null ? this.state.image2 : shoppingBag}
                                                         onClick={e => {
                                                             imageSelector(file => {
                                                                 this.setState({
                                                                     image2: file,
                                                                     cropperModal2: true
                                                                 })
                                                             }, MIME_PNG_JPEG, STATION_IMAGE_MAX_FILE_SIZE).click()
                                                         }}
                                                         onError={(elm) => this.defaultImage(elm, shoppingBag)}/>
                                                <CardBody>
                                                    <CardTitle>Station Image</CardTitle>
                                                    <Row>
                                                        <Col>
                                                            <WarningLabel
                                                                message={"*Use PNG or Jpeg Format / Max " + STATION_IMAGE_MAX_FILE_SIZE + " Kb"}/>
                                                        </Col>
                                                    </Row>
                                                    <br/>
                                                    <Row>
                                                        <Col>
                                                            <ButtonGroup className="float-left">
                                                                <Button color="danger" onClick={e => (
                                                                    this.setState({
                                                                        station: null,
                                                                        image2: null
                                                                    })
                                                                )}>{label.Remove}</Button>
                                                            </ButtonGroup>
                                                        </Col>
                                                        <Col>
                                                            <ButtonGroup className="float-right">
                                                                <Button color="primary" onClick={e => {
                                                                    imageSelector(file => {
                                                                        this.setState({
                                                                            image2: file,
                                                                            cropperModal2: true
                                                                        })
                                                                    }, MIME_PNG_JPEG, STATION_IMAGE_MAX_FILE_SIZE).click()
                                                                }}>{label.Upload}</Button>

                                                            </ButtonGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={3}>
                                            <Card>
                                                <CardImg top src={
                                                    this.state.image3 != null ? this.state.image3 : shoppingBag}
                                                         onClick={e => {
                                                             imageSelector(file => {
                                                                 this.setState({
                                                                     image3: file,
                                                                     cropperModal3: true
                                                                 })
                                                             }, MIME_PNG_JPEG, STATION_IMAGE_MAX_FILE_SIZE).click()
                                                         }}
                                                         onError={(elm) => this.defaultImage(elm, shoppingBag)}/>
                                                <CardBody>
                                                    <CardTitle>Station Image</CardTitle>
                                                    <Row>
                                                        <Col>
                                                            <WarningLabel
                                                                message={"*Use PNG or Jpeg Format / Max " + STATION_IMAGE_MAX_FILE_SIZE + " Kb"}/>
                                                        </Col>
                                                    </Row>
                                                    <br/>
                                                    <Row>
                                                        <Col>
                                                            <ButtonGroup className="float-left">
                                                                <Button color="danger" onClick={e => (
                                                                    this.setState({
                                                                        station: null,
                                                                        image3: null
                                                                    })
                                                                )}>{label.Remove}</Button>
                                                            </ButtonGroup>
                                                        </Col>
                                                        <Col>
                                                            <ButtonGroup className="float-right">
                                                                <Button color="primary" onClick={e => {
                                                                    imageSelector(file => {
                                                                        this.setState({
                                                                            image3: file,
                                                                            cropperModal3: true
                                                                        })
                                                                    }, MIME_PNG_JPEG, STATION_IMAGE_MAX_FILE_SIZE).click()
                                                                }}>{label.Upload}</Button>

                                                            </ButtonGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={3}>
                                            <Card>
                                                <CardImg top src={
                                                    this.state.image4 != null ? this.state.image4 : shoppingBag}
                                                         onClick={e => {
                                                             imageSelector(file => {
                                                                 this.setState({
                                                                     image1: file,
                                                                     cropperModal4: true
                                                                 })
                                                             }, MIME_PNG_JPEG, STATION_IMAGE_MAX_FILE_SIZE).click()
                                                         }}
                                                         onError={(elm) => this.defaultImage(elm, shoppingBag)}/>
                                                <CardBody>
                                                    <CardTitle>Station Image</CardTitle>
                                                    <Row>
                                                        <Col>
                                                            <WarningLabel
                                                                message={"*Use PNG or Jpeg Format / Max " + STATION_IMAGE_MAX_FILE_SIZE + " Kb"}/>
                                                        </Col>
                                                    </Row>
                                                    <br/>
                                                    <Row>
                                                        <Col>
                                                            <ButtonGroup className="float-left">
                                                                <Button color="danger" onClick={e => (
                                                                    this.setState({
                                                                        station: null,
                                                                        image4: null
                                                                    })
                                                                )}>{label.Remove}</Button>
                                                            </ButtonGroup>
                                                        </Col>
                                                        <Col>
                                                            <ButtonGroup className="float-right">
                                                                <Button color="primary" onClick={e => {
                                                                    imageSelector(file => {
                                                                        this.setState({
                                                                            image4: file,
                                                                            cropperModal4: true
                                                                        })
                                                                    }, MIME_PNG_JPEG, STATION_IMAGE_MAX_FILE_SIZE).click()
                                                                }}>{label.Upload}</Button>
                                                            </ButtonGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Card>
                                                <CardHeader>{label.Information}</CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="name">{label.Name}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="name"
                                                                    value={(station) ? station.name : ""}
                                                                    onChange={(e) => {
                                                                        e.preventDefault()
                                                                        station.name = e.target.value
                                                                        validation.name = undefined
                                                                        this.setState({
                                                                            station: station,
                                                                            validation
                                                                        })

                                                                    }}
                                                                    placeholder={label.EnterName}
                                                                />
                                                                <WarningLabel show={validation.name} message={validation.name}/>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <Row>
                                                                <Col md={9}>
                                                                    <FormGroup>
                                                                        <Label for="code">{label.Code}</Label>
                                                                        <Input
                                                                            type="text"
                                                                            name="code"
                                                                            value={(station) ? station.code : ""}
                                                                            onChange={(e) => {
                                                                                e.preventDefault()
                                                                                station.code = e.target.value
                                                                                validation.code = undefined
                                                                                this.setState({
                                                                                    station: station,
                                                                                    validation
                                                                                }, () => {
                                                                                    this.validateCode()
                                                                                })
                                                                            }}
                                                                        />
                                                                        <WarningLabel show={validation.code} message={validation.code}/>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col md={3}>
                                                                    <FormGroup>
                                                                        <Label>&nbsp;</Label>
                                                                        <Button block onClick={(e)=>{
                                                                            this.generateCode()
                                                                        }}><IoMdKey/></Button>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="name">{label.EnterBuildDate}</Label>
                                                                <DateInput
                                                                    id="buildDate"
                                                                    maxdate={new Date()}
                                                                    value={station.buildDate}
                                                                    onChange={(e) => {
                                                                        let value = e.target.value
                                                                        station.buildDate = value
                                                                        validation.buildDate = undefined
                                                                        this.setState({
                                                                            station: station
                                                                        })
                                                                    }}
                                                                    placeholder={label.EnterBuildDate}>
                                                                </DateInput>
                                                                <WarningLabel show={validation.buildDate} message={validation.buildDate}/>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="name">{label.EnterPhoneNumber}</Label>
                                                                <NumberInput
                                                                    name="phoneNumber"
                                                                    className="form-control"
                                                                    value={(station) ? station.phoneNumber : ""}
                                                                    onChangeEvent={(e, maskedvalue, floatvalue) => {
                                                                        e.preventDefault()
                                                                        if (station != null) {
                                                                            station.phoneNumber = e.target.value
                                                                            validation.phoneNumber = undefined
                                                                            this.setState({
                                                                                station: station, validation
                                                                            })
                                                                        }
                                                                    }}
                                                                    maxLength="15"
                                                                    placeholder="0">
                                                                </NumberInput>
                                                                <WarningLabel show={validation.phoneNumber} message={validation.phoneNumber}/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <ActiveOption
                                                                    default={station.active}
                                                                    callback={(active)=>{
                                                                        station.active = active
                                                                        this.setState({
                                                                            station:station
                                                                        })
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="name">{label.Description}</Label>
                                                                <Input
                                                                    type="textarea"
                                                                    rows={4}
                                                                    name="description"
                                                                    value={(station) ? station.description : ""}
                                                                    onChange={(e) => {
                                                                        e.preventDefault()
                                                                        if (station != null) {
                                                                            station.description = e.target.value
                                                                            this.setState({
                                                                                station: station
                                                                            })
                                                                        }
                                                                    }}
                                                                    placeholder={label.EnterDescription}
                                                                />
                                                                <WarningLabel show={validation.description} message={validation.description}/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={6}>
                                            <Card>
                                                <CardHeader>{label.DailyOperation}</CardHeader>
                                                <CardBody>
                                                    <Table responsive hover>
                                                        <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>{label.Name}</th>
                                                            <th>{label.Open}</th>
                                                            <th>{label._24Hours}</th>
                                                            <th>{label.OpenOperational}</th>
                                                            <th>{label.CloseOperational}</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            weeklyOpens.map((item, index) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{index + 1}</td>
                                                                        <td>{item.dayName}</td>
                                                                        <td>
                                                                            <Checkbox
                                                                                checked={item.open}
                                                                                onChange={(checked) => {
                                                                                    weeklyOpens[index].openTime = undefined
                                                                                    weeklyOpens[index].closeTime = undefined
                                                                                    weeklyOpens[index].openErrorMessage = undefined
                                                                                    weeklyOpens[index].closeErrorMessage = undefined
                                                                                    if (checked) {
                                                                                        weeklyOpens[index]._24Hours = true
                                                                                        weeklyOpens[index].open = true
                                                                                    }else{
                                                                                        weeklyOpens[index].open = false
                                                                                        weeklyOpens[index]._24Hours = false
                                                                                    }
                                                                                    this.setState({weeklyOpens})
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <Checkbox
                                                                                checked={item._24Hours}
                                                                                onChange={(checked) => {
                                                                                    if (checked) {
                                                                                        weeklyOpens[index].openTime = undefined
                                                                                        weeklyOpens[index].closeTime = undefined
                                                                                        weeklyOpens[index].openErrorMessage = undefined
                                                                                        weeklyOpens[index].closeErrorMessage = undefined
                                                                                    }
                                                                                    weeklyOpens[index]._24Hours = true
                                                                                    this.setState({weeklyOpens})
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <TimePicker
                                                                                onChange={(time) => {
                                                                                    let timeSelected = parseDate(time, 'HH:MM')
                                                                                    weeklyOpens[index]._24Hours = false
                                                                                    weeklyOpens[index].openTime = timeSelected
                                                                                    this.setState({
                                                                                        weeklyOpens,
                                                                                        openErrorMessage: undefined
                                                                                    })
                                                                                }}
                                                                                value={item.openTime ? moment(item.openTime, 'HH:mm') : undefined}
                                                                                format={'HH:mm'}
                                                                            />
                                                                            {
                                                                                item.openErrorMessage ? (
                                                                                    <><br/><span
                                                                                        style={{color: '#900303'}}>{item.openErrorMessage}</span></>
                                                                                ) : null
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <TimePicker
                                                                                onChange={(time) => {
                                                                                    let timeSelected = parseDate(time, 'HH:MM')
                                                                                    weeklyOpens[index]._24Hours = false
                                                                                    weeklyOpens[index].closeTime = timeSelected
                                                                                    this.setState({
                                                                                        weeklyOpens,
                                                                                        closeErrorMessage: undefined
                                                                                    })
                                                                                }}
                                                                                value={item.closeTime ? moment(item.closeTime, 'HH:mm') : undefined}
                                                                                format={'HH:mm'}/>
                                                                            {
                                                                                item.closeErrorMessage ? (
                                                                                    <><br/><span
                                                                                        style={{color: '#900303'}}>{item.closeErrorMessage}</span></>
                                                                                ) : null
                                                                            }

                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                        </tbody>
                                                    </Table>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader>{label.Address}</CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col>
                                                            <MapContainer
                                                                latitude={station.latitude}
                                                                longitude={station.longitude}
                                                                onGestureExecuting={(latitude, longitude) => {
                                                                    station.latitude = latitude
                                                                    station.longitude = longitude
                                                                    console.log("LATLNG = " + latitude + " - " + longitude)
                                                                    this.setState({station: station})
                                                                }}/>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="name">{label.Address}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="address"
                                                                    value={(station) ? station.address : ""}
                                                                    onChange={(e) => {
                                                                        e.preventDefault()
                                                                        station.address = e.target.value
                                                                        validation.address = undefined
                                                                        this.setState({
                                                                            station: station, validation
                                                                        })
                                                                        }
                                                                    }
                                                                    placeholder={label.Address}
                                                                />
                                                                <WarningLabel show={validation.address} message={validation.address}/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <ItemOption
                                                                    title={label.SelectProvince}
                                                                    objects={this.state.provinces}
                                                                    default={!allIsEmpty(this.state.province) ? this.state.province.id : null}
                                                                    callback={(province) => {
                                                                        if (province != null) {
                                                                            this.setState({
                                                                                province: province,
                                                                                cities: [],
                                                                                districts: [],
                                                                                villages: [],
                                                                            }, () => {
                                                                                this.fetchCities(province.id, cities => {
                                                                                    this.setState({
                                                                                        cities: cities
                                                                                    })
                                                                                })
                                                                            })
                                                                        } else {
                                                                            this.setState({
                                                                                province: null,
                                                                                cities: [],
                                                                                districts: [],
                                                                                villages: [],
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <ItemOption
                                                                    title={label.SelectCity}
                                                                    objects={this.state.cities}
                                                                    default={!allIsEmpty(this.state.city) ? this.state.city.id : null}
                                                                    callback={(city) => {
                                                                        if (city != null) {
                                                                            this.setState({
                                                                                city: city,
                                                                                districts: [],
                                                                                villages: [],
                                                                            }, () => {
                                                                                this.fetchDistricts(city.id, districts => {
                                                                                    this.setState({
                                                                                        districts: districts
                                                                                    })
                                                                                })
                                                                            })
                                                                        } else {
                                                                            this.setState({
                                                                                city: null,
                                                                                districts: [],
                                                                                villages: [],
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <ItemOption
                                                                    title={label.SelectDistrict}
                                                                    objects={this.state.districts}
                                                                    default={!allIsEmpty(this.state.district) ? this.state.district.id : null}
                                                                    callback={(district) => {
                                                                        if (district != null) {
                                                                            this.setState({
                                                                                district: district,
                                                                                villages: [],
                                                                            }, () => {
                                                                                this.fetchVillages(district.id, villages => {
                                                                                    this.setState({
                                                                                        villages: villages
                                                                                    })
                                                                                })
                                                                            })
                                                                        } else {
                                                                            this.setState({
                                                                                district: null,
                                                                                villages: [],
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <ItemOption
                                                                    title={label.SelectVillage}
                                                                    objects={this.state.villages}
                                                                    default={!allIsEmpty(this.state.village) ? this.state.village.id : null}
                                                                    callback={(village) => {
                                                                        if (village != null) {
                                                                            let customer = this.state.customer
                                                                            station.village = village
                                                                            this.setState({
                                                                                station: station,
                                                                                village: village,
                                                                            })
                                                                        }
                                                                    }}
                                                                />
                                                                <WarningLabel show={validation.village} message={validation.village}/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <ButtonGroup className="float-left">
                                                <Button color="danger" onClick={e => (
                                                    this.setState({
                                                        station: null
                                                    }, () => {
                                                        this.props.history.goBack();
                                                    })
                                                )}>{label.Back}</Button>
                                            </ButtonGroup>
                                        </Col>
                                        <Col>
                                            <ButtonGroup className="float-right">
                                                <Button color="primary" onClick={e => {
                                                    e.preventDefault()
                                                    this.confirmSave()
                                                }}> {
                                                    (this.state.station != null && this.state.station.id != null) ? label.Update : label.Create
                                                } </Button>

                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <ImageCropper src={this.state.image1} aspect={aspect} show={this.state.cropperModal1}
                                  callback={file => {
                                      axios({
                                          url: file,
                                          method: 'GET',
                                          responseType: 'blob', // important
                                      }).then((response) => {
                                          var fileBlob = response.data;
                                          this.setState({
                                              image1: file,
                                              cropperModal1: false,
                                              imageBlob1: fileBlob,
                                          })
                                      })
                                  }} cancelCallback={() => {
                        this.setState({
                            cropperModal1: false,
                            image1: station.imageLink1,
                            imageBlob1: null,
                        })
                    }}/>
                    <ImageCropper src={this.state.image2} aspect={aspect} show={this.state.cropperModal2}
                                  callback={file => {
                                      axios({
                                          url: file,
                                          method: 'GET',
                                          responseType: 'blob', // important
                                      }).then((response) => {
                                          var fileBlob = response.data;
                                          this.setState({
                                              image2: file,
                                              cropperModal2: false,
                                              imageBlob2: fileBlob,
                                          })
                                      })
                                  }} cancelCallback={() => {
                        this.setState({
                            cropperModal2: false,
                            image2: station.imageLink2,
                            imageBlob2: null,
                        })
                    }}/>
                    <ImageCropper src={this.state.image3} aspect={aspect} show={this.state.cropperModal3}
                                  callback={file => {
                                      axios({
                                          url: file,
                                          method: 'GET',
                                          responseType: 'blob', // important
                                      }).then((response) => {
                                          var fileBlob = response.data;
                                          this.setState({
                                              image3: file,
                                              cropperModal3: false,
                                              imageBlob3: fileBlob,
                                          })
                                      })
                                  }} cancelCallback={() => {
                        this.setState({
                            cropperModal3: false,
                            image3: station.imageLink3,
                            imageBlob3: null,
                        })
                    }}/>
                    <ImageCropper src={this.state.image4} aspect={aspect} show={this.state.cropperModal4}
                                  callback={file => {
                                      axios({
                                          url: file,
                                          method: 'GET',
                                          responseType: 'blob', // important
                                      }).then((response) => {
                                          var fileBlob = response.data;
                                          this.setState({
                                              image4: file,
                                              cropperModal4: false,
                                              imageBlob4: fileBlob,
                                          })
                                      })
                                  }} cancelCallback={() => {
                        this.setState({
                            cropperModal4: false,
                            image4: station.imageLink4,
                            imageBlob4: null,
                        })
                    }}/>

                </Page>
            );
        }

}
export default Object.assign(StationDetailPage, {ENDPOINT : "/stationDetail"})